import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {ApiService} from "../../../services/api.service";
import {Ad, AdList, AdType, PriceType} from "../../../interfaces/ad";
import {AuthenticationService} from "../../../services/auth.service";
import {SerializedParamCollection} from "../../../utils/serializedParameter";
import {CursorToken} from "../../../interfaces/utils";
import {getDummyPicture} from "../../../utils/dummyImages";
import {setLastSeen, setPreviousAd} from "../../../utils/sessionStorage";
import {SmartAdType} from "../../../interfaces/smartAd";
import {ToastService} from "../../../services/toast.service";

@Component({
  selector: 'kaz-detail-ad',
  templateUrl: './detail-ad.component.html',
  styleUrls: ['./detail-ad.component.scss']
})
export class DetailAdComponent implements OnInit {
  ad: Ad | null = null;
  newestAds: AdList | null = null;

  showFastMessage: boolean = false;
  showReportAd: boolean = false;

  imageIndex: number = 0;

  nextToken: CursorToken = "@start";
  filters: SerializedParamCollection = {
    search: {
      active: null,
      default: null,
      type: 'string',
    },
  };

  today: Date = new Date();
  expired: Date = new Date();
  publication: Date = new Date();

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _api: ApiService,
              private _location: Location,
              private _toast: ToastService,
              private _auth: AuthenticationService) {
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      const adId = params['id']!;
      this.getAd(adId).then();
      // Set to 0 by new ad
      this.imageIndex = 0;
      // set LastSeen
      setLastSeen(adId);
      //   scroll to top
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          document.getElementById("main_layout_scroll")?.scrollTo({top: 0, behavior: 'smooth'});
        }
      });

      this.getNewestAds().then();
    });
  }

  async getAd(id: string) {
    try {
      if (id !== '') {
        this.ad = await this._api.getAd(id);
        this.sortImages();

        this.expired = new Date(this.ad.expired);
        this.publication = new Date(this.ad.publicationDate[0]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  copyLink() {
    navigator.clipboard.writeText(window.location.href).then();
    this._toast.showToast(null, "Link kopiert! 🥳");
  }

  shareLink() {
    navigator.share({
      title: 'Schau was ich auf kleinanzeigen.bo.de gefunden habe:',
      text: this.ad?.title,
      url: window.location.href,
    }).then().catch((error) => {
      console.error('Fehler beim Teilen der Anzeige:', error);
    });
  }

  openFastMessage() {
    if (this._auth.isAuthenticated())
      this.showFastMessage = true;
    else
      this._router.navigate(['profile/info']).then();
  }

  lastImage() {
    if (this.imageIndex > 0)
      this.imageIndex--;
  }


  nextImage() {
    if (this.ad !== null && this.imageIndex <= this.ad.images.length)
      this.imageIndex++;
  }

  private sortImages() {
    if (this.ad !== null && this.ad.titleImageIndex !== 0) {
      // Put titleImage in first place
      const idToMove = this.ad.images.splice(this.ad.titleImageIndex, 1)[0];
      this.ad.images.unshift(idToMove);
    }
  }

  async getNewestAds() {
    try {
      this.newestAds = await this._api.getAdList(this.nextToken, 3, this.filters);
    } catch (err) {

    }
  }

  goBack() {
    this._location.back();
  }

  isOwnAd(authorId: string) {
    return this.ad !== null && this._auth.getSelfID() === authorId;
  }

  protected readonly getDummyPicture = getDummyPicture;

  isInTimeSlot() {
    return this.today < this.expired && this.today > this.publication;
  }

  isAfterTimeSlot() {
    return this.today > this.expired;
  }

  async reCreatAd(ad: Ad) {
    setPreviousAd(ad);
    this._router.navigate(['/entry/create']).then();
  }

  protected readonly SmartAdType = SmartAdType;
  protected readonly AdType = AdType;
  protected readonly PriceType = PriceType;
}

import {RFC3339} from "./datetime";
import {Base64, CursorToken} from "./utils";
import {Cipher} from "./cipher";
import {BillingAddress} from "./address";

export interface Ad {
  id: string;
  authorId: string;
  images: string[];
  titleImageIndex: number;
  category: Category;
  adType: AdType;
  title: string;
  description: string;
  cipher: Cipher;
  priceType: PriceType;
  price: number;
  publicationDate: RFC3339[];
  created: RFC3339;
  expired: RFC3339;
  reserved: boolean;
  validated: boolean;
  paid: boolean;
  deleted: RFC3339;
  refunded: boolean;
}

export interface AdList {
  ads: Ad[];
  nextPageToken: CursorToken;
  pageSize: number;
}

export interface CreateAd {
  transferOldImages: boolean;
  previousAdId: string;
  imageData: Base64[];
  imageContentType: string[];
  titleImageIndex: number;
  category: Category;
  adType: AdType;
  title: string;
  description: string;
  cipher: Partial<Cipher>;
  priceType: PriceType;
  price: number;
  publicationDate: RFC3339[];
  billingAddress: BillingAddress;
}

export interface Category {
  name: string;
  subName: string;
  localityName: string;
}

export enum AdType {
  OFFER = "OFFER",
  WANTED = "WANTED",
  GOOD_DEED = "GOOD_DEED",
}

export enum PriceType {
  FP = "FP" ,
  VHB = "VHB" ,
}

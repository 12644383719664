import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {User} from "../../interfaces/user";
import {AuthenticationService} from "../../services/auth.service";
import {ActivatedRoute} from "@angular/router";
import {NavigationBar, NavigationBarItem} from "../../interfaces/navigation";

@Component({
  selector: 'kaz-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile: User | null = null;

  navBar: NavigationBar[] = [
    {name: "Anzeigen", value: NavigationBarItem.ENTRIES, current: true},
    {name: "Nachrichten", value: NavigationBarItem.MESSAGES, current: false},
    {name: "Archiv", value: NavigationBarItem.ARCHIVE, current: false},
    // {name: 'FAVORITES', current: false},
  ];

  constructor(private _api: ApiService,
              private _route: ActivatedRoute,
              private _auth: AuthenticationService) {
  }

  ngOnInit() {
    this.getOwnUser().then();
    this._route.params.subscribe(params => {
      const menu = params['menu'];
      this.setAsActive(menu ?? NavigationBarItem.ENTRIES);
    })
  }

  private async getOwnUser() {
    const userId = this._auth.getClaimsFromToken()?.id
    if (userId !== undefined)
      this.profile = await this._api.getOwnUser(userId);
  }

  getInitials() {
    if (this.profile !== null && this.profile !== undefined) {
      return this.profile.firstname[0] + this.profile.lastname[0];
    } else {
      return "--"
    }
  }

  setAsActive(navValue: NavigationBarItem) {
    for (const tab of this.navBar) {
      tab.current = tab.value === navValue;
    }
  }

  isActive(navValue: NavigationBarItem) {
    let nav = this.navBar.find(item => item.value === navValue)
    return nav !== undefined && nav.current;
  }

  protected readonly NavigationBarItem = NavigationBarItem;
}

import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AuthenticationService} from "./auth.service";
import {APIMethod, APIRequestMethod, APIServiceRef} from "../utils/apiMethod";
import {NewUser, User} from "../interfaces/user";
import {Ad, AdList, CreateAd} from "../interfaces/ad";
import {SerializedParamCollection} from "../utils/serializedParameter";
import {CursorToken} from "../interfaces/utils";
import {Favorite} from "../interfaces/favorite";
import {Chat, ChatList, CreateChat} from "../interfaces/chat";
import {Message} from "../interfaces/message";
import {CreateReport, Report} from "../interfaces/report";


@Injectable({providedIn: 'root'})
export class ApiService {
  private static ENDPOINTS = {
    //auth
    refresh:                          new APIMethod<{ token: string }>(APIRequestMethod.GET,                  'auth/refresh'),
    login:                            new APIMethod<{ token: string, user: User }>(APIRequestMethod.POST,     'webuser/auth/login'),
    signup:                           new APIMethod<{ token: string, user: User }>(APIRequestMethod.POST,     'webuser/auth/signup'),
    setResetPasswordToken:            new APIMethod<{}>(APIRequestMethod.POST,                                'webuser/auth/reset-password-token'),
    resetPassword:                    new APIMethod<User>(APIRequestMethod.PATCH,                             'webuser/auth/{id}/{token}'),

    //webusers
    getOwnUser:                       new APIMethod<User>(APIRequestMethod.GET,                               'webuser/{id}'),
    updateWebUser:                    new APIMethod<User>(APIRequestMethod.PATCH,                             'webuser/{id}'),
    verifyWebUser:                    new APIMethod<User>(APIRequestMethod.PATCH,                             'webuser/{id}/{token}'),
    sendVerifyMail:                   new APIMethod<User>(APIRequestMethod.GET,                               'webuser/mail/{id}'),

    //ads
    getAdList:                        new APIMethod<AdList>(APIRequestMethod.GET,                             'entry/'),
    getOwnAdList:                     new APIMethod<AdList>(APIRequestMethod.GET,                             'webuser/{id}/entry'),
    getActiveAdList:                  new APIMethod<AdList>(APIRequestMethod.GET,                             'webuser/{id}/entry/active'),
    getArchivedAdList:                new APIMethod<AdList>(APIRequestMethod.GET,                             'webuser/{id}/entry/archived'),
    getAd:                            new APIMethod<Ad>(APIRequestMethod.GET,                                 'entry/{id}'),
    createAd:                         new APIMethod<Ad>(APIRequestMethod.POST,                                'entry/'),
    updateAd:                         new APIMethod<Ad>(APIRequestMethod.PATCH,                               'entry/{id}'),
    deleteAd:                         new APIMethod<{}>(APIRequestMethod.DELETE,                              'entry/{id}'),
    createAdCheckoutSession:          new APIMethod<string>(APIRequestMethod.POST,                            'entry/checkout'),
    getLastSeen:                      new APIMethod<Ad[]>(APIRequestMethod.GET,                               'entry/lastSeen/{ids}'),

    //favorites
    getFavoriteListFromAd:            new APIMethod<Favorite[]>(APIRequestMethod.GET,                         'entry/{adId}/favorite'),
    getFavoriteFromAd:                new APIMethod<Favorite>(APIRequestMethod.GET,                           'entry/favorite/{adId}'),
    getFavoriteListFromWebUser:       new APIMethod<Favorite[]>(APIRequestMethod.GET,                         'webuser/favorite'),
    createFavorite:                   new APIMethod<Favorite>(APIRequestMethod.POST,                          'favorite/'),
    deleteFavorite:                   new APIMethod<{}>(APIRequestMethod.DELETE,                              'favorite/{id}'),

    //chats
    createChat:                       new APIMethod<Chat>(APIRequestMethod.POST,                              'chat/'),
    updateChat:                       new APIMethod<Chat>(APIRequestMethod.PATCH,                             'chat/{id}'),
    getOwnChatList:                   new APIMethod<ChatList>(APIRequestMethod.GET,                           'webuser/{id}/chat'),

    //messages
    getMessage:                       new APIMethod<Message>(APIRequestMethod.GET,                            'message/{id}'),
    getMessagesOfChat:                new APIMethod<Message[]>(APIRequestMethod.GET,                          'chat/{id}/message'),

    //reports
    createReport:                     new APIMethod<Report>(APIRequestMethod.POST,                            'report/'),
  }

  private readonly ref: APIServiceRef;

  constructor(private http: HttpClient,
              private auth: AuthenticationService) {
    this.ref = ({http: http, auth: auth, api: this});
  }

  login(identifier: string, password: string) {
    return ApiService.ENDPOINTS.login.run(this.ref, {}, {identifier, password});
  }

  refreshToken() {
    return ApiService.ENDPOINTS.refresh.run(this.ref, {}, {});
  }

  signup(user: Partial<NewUser>) {
    return ApiService.ENDPOINTS.signup.run(this.ref, {}, user);
  }

  setResetPasswordToken(mail: string) {
    return ApiService.ENDPOINTS.setResetPasswordToken.run(this.ref, {}, {mail});
  }

  getOwnUser(id: string) {
    return ApiService.ENDPOINTS.getOwnUser.run(this.ref, {':id': id}, {});
  }

  updateWebUser(user: Partial<User>) {
    return ApiService.ENDPOINTS.updateWebUser.run(this.ref, {':id': user.id}, user);
  }

  verifyWebUser(user: Partial<User>, token: string) {
    return ApiService.ENDPOINTS.verifyWebUser.run(this.ref, {':id': user.id, ':token': token}, user);
  }

  sendVerifyMail(id: string) {
    return ApiService.ENDPOINTS.sendVerifyMail.run(this.ref, {':id': id}, {});
  }

  getAdList(token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return ApiService.ENDPOINTS.getAdList.run(this.ref, {'nextPageToken': token, 'pageSize': pageSize}, {}, filter);
  }

  getSearchedAdList(search: string, token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return ApiService.ENDPOINTS.getAdList.run(this.ref, {'search': search,'nextPageToken': token, 'pageSize': pageSize}, {}, filter);
  }

  getOwnAdList(token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return ApiService.ENDPOINTS.getOwnAdList.run(this.ref, {
      ':id': this.auth.getClaimsFromToken()?.id,
      'nextPageToken': token,
      'pageSize': pageSize
    }, {}, filter);
  }

  getActiveAdList(token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return ApiService.ENDPOINTS.getActiveAdList.run(this.ref, {
      ':id': this.auth.getClaimsFromToken()?.id,
      'nextPageToken': token,
      'pageSize': pageSize
    }, {}, filter);
  }

  getArchivedAdList(token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return ApiService.ENDPOINTS.getArchivedAdList.run(this.ref, {
      ':id': this.auth.getClaimsFromToken()?.id,
      'nextPageToken': token,
      'pageSize': pageSize
    }, {}, filter);
  }

  getAd(id: string) {
    return ApiService.ENDPOINTS.getAd.run(this.ref, {':id': id}, {});
  }

  createAd(createAd: Partial<CreateAd>) {
    return ApiService.ENDPOINTS.createAd.run(this.ref, {}, createAd);
  }

  createAdCheckoutSession(adId: string, authorId: string) {
    return ApiService.ENDPOINTS.createAdCheckoutSession.run(this.ref, {}, {adId:adId, authorId: authorId});
  }

  updateAd(ad: Partial<Ad>) {
    return ApiService.ENDPOINTS.updateAd.run(this.ref, {':id': ad.id}, ad);
  }

  deleteAd(adId: string) {
    return ApiService.ENDPOINTS.deleteAd.run(this.ref, {':id': adId}, {});
  }

  async getLastSeen(lastSeenIdsString: string) {
    return ApiService.ENDPOINTS.getLastSeen.run(this.ref, {':ids': lastSeenIdsString}, {});
  }

  getFavoriteListFromAd(adId: string) {
    return ApiService.ENDPOINTS.getFavoriteListFromAd.run(this.ref, {':adId': adId}, {});
  }

  getFavoriteFromAd(adId: string) {
    return ApiService.ENDPOINTS.getFavoriteFromAd.run(this.ref, {':adId': adId}, {});
  }

  getFavoriteListFromWebUser() {
    return ApiService.ENDPOINTS.getFavoriteListFromWebUser.run(this.ref, {}, {});
  }

  createFavorite(adId: string) {
    return ApiService.ENDPOINTS.createFavorite.run(this.ref, {}, {adId});
  }

  deleteFavorite(favoriteId: string) {
    return ApiService.ENDPOINTS.deleteFavorite.run(this.ref, {':id': favoriteId}, {});
  }

  createChat(createChat: CreateChat) {
    return ApiService.ENDPOINTS.createChat.run(this.ref, {}, createChat);
  }

  getOwnChatList(token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return ApiService.ENDPOINTS.getOwnChatList.run(this.ref, {
      ':id': this.auth.getClaimsFromToken()?.id,
      'nextPageToken': token,
      'pageSize': pageSize
    }, {}, filter);
  }

  getMessage(id: string) {
    return ApiService.ENDPOINTS.getMessage.run(this.ref, {':id': id}, {});
  }

  getMessagesOfChat(id: string) {
    return ApiService.ENDPOINTS.getMessagesOfChat.run(this.ref, {':id': id}, {});
  }

  updateChat(chatId: string, msg: string) {
    return ApiService.ENDPOINTS.updateChat.run(this.ref, {':id': chatId}, {'message':msg});
  }

  createReport(report: CreateReport) {
    return ApiService.ENDPOINTS.createReport.run(this.ref, {}, report);
  }

  resetPassword(id: string, token: string, password: string) {
    return ApiService.ENDPOINTS.resetPassword.run(this.ref, {':id': id, ':token': token}, {password});
  }
}

import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toastSubject = new Subject<{ title: string | null; description: string | null }>();
  toastState$ = this.toastSubject.asObservable();

  constructor() {}

  showToast(title: string | null, description: string | null) {
    this.toastSubject.next({ title, description });
  }
}

import {Component, Input} from '@angular/core';
import {SmartAdType} from "../../interfaces/smartAd";

@Component({
  selector: 'kaz-smart-entry',
  templateUrl: './smart-entry.component.html',
  styleUrls: ['./smart-entry.component.scss']
})
export class SmartEntryComponent {
  @Input() class: string = '';
  @Input() type: SmartAdType = SmartAdType.RECTANGLE;
  protected readonly SmartAdType = SmartAdType;
}

import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {Chat, ChatList} from "../../interfaces/chat";
import {CursorToken} from "../../interfaces/utils";
import {Ad} from "../../interfaces/ad";
import {Message} from "../../interfaces/message";
import {AuthenticationService} from "../../services/auth.service";

@Component({
  selector: 'kaz-messages',
  templateUrl: './kaz-messages.component.html',
  styleUrls: ['./kaz-messages.component.scss']
})
export class KazMessagesComponent implements OnInit, AfterViewChecked {
  @ViewChild('chatHistory') chatHistory!: ElementRef;

  ownChats: ChatList | null = null;
  activeChat: Chat | null = null;

  ad: Ad | null = null;
  messages: Message[] | null = null;
  msg: string = '';

  nextToken: CursorToken = "@start";

  private messagesAreOpen: boolean = true;

  constructor(private _api: ApiService,
              private _auth: AuthenticationService) {
  }

  async ngOnInit() {
    await this.getOwnChats();
    await this.getOwnChatsEveryInterval();
  }

  ngOnDestroy(): void {
    this.messagesAreOpen = false;
  }

  ngAfterViewChecked() {
    if (this.chatHistory) {
      this.chatHistory.nativeElement.scrollTop = this.chatHistory.nativeElement.scrollHeight;
    }
  }

  async getOwnChats() {
    try {
      const data = await this._api.getOwnChatList(this.nextToken, 24);
      if (data.chats.length !== this.ownChats?.chats.length) {
        this.ownChats = data;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async setActiveChat(chat: Chat) {
    this.activeChat = chat;
    await this.getAd(chat.adID);
    await this.getMessagesOfChat();
  }

  async getAd(adID: string) {
    try {
      this.ad = await this._api.getAd(adID);
    } catch (err) {
      console.log(err);
    }
  }

  private async getMessagesOfChat() {
    try {
      if (this.activeChat !== null){
        this.messages = await this._api.getMessagesOfChat(this.activeChat.id)
      }
    } catch (err) {
      console.log(err);
    }
  }

  async sendMessage() {
    try {
      if (this.activeChat !== null && this.msg !== ''){
        this.activeChat = await this._api.updateChat(this.activeChat.id, this.msg);
        this.msg = '';
        await this.getMessagesOfChat();
      }
    } catch (err) {
      console.log(err);
    }
  }

  isOwnMsg(id: string) {
    const userId = this._auth.getSelfID();
    return userId !== null && userId === id;
  }

  private async getOwnChatsEveryInterval() {
    if (this.messagesAreOpen){
      setTimeout(() => {
        this.getOwnChats();
        this.getOwnChatsEveryInterval();
      }, 10000)
    }
  }
}



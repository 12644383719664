import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ApiService} from "../../../services/api.service";
import {AuthenticationService} from "../../../services/auth.service";


@Component({
    selector: 'kaz-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {
  mail: string = '';
  password: string = '';

  showPasswordPage: boolean = false;
  showResetPassword: boolean = false;

  saveLogin: boolean = false;

  loginErrorText: string = '';

  constructor(private _router: Router,
              private _api: ApiService,
              private _auth: AuthenticationService) {
  }

  ngOnInit() {
    this.isAuthenticated();
  }

  isAuthenticated() {
    if (this._auth.isAuthenticated()) {
      const claims = this._auth.getClaimsFromToken();
      if (claims !== null) {
        if (claims.userType === 'WEB_USER') {
          this._router.navigate(['profile']).then();
        }
        if (claims.userType === 'ADMIN_USER') {
          this._auth.clearAuth();
          this._router.navigate(['login']).then();
        }
      }
    }
  }

  setMail() {
    if (this.mail !== '' && this.mail.includes('@') && this.mail.includes('.')) {
      this.showPasswordPage = true;
    }
  }


  rewriteMail() {
    this.mail = '';
    this.loginErrorText = '';
    this.showPasswordPage = false;
  }

  setShowResetButton() {
    this.showResetPassword = !this.showResetPassword;
  }

  resetPassword() {
    this._router.navigate(['reset'], {queryParams: {mail: this.mail}}).then();
  }

  async login() {
    try {
      const data = await this._api.login(this.mail, this.password);
      if (data.user.id !== "" && !data.user.verified) {
        await this._router.navigate(['validate', data.user.id]);
        return;
      }

      if (data.token === null || data.token === '') {
        await this._router.navigate(['validate', data.user.id]);
        return;
      }
      this._auth.setAuth(data.token);
      await this._router.navigate(['main']);

    } catch (err: any) {
      switch (err.status) {
        case 500:
        case 400:
        case 404:
        case 401:
          this.loginErrorText = 'Ihre eingegebenen Daten sind nicht korrekt. Bitte überprüfen Sie diese und versuchen Sie es erneut.';
          break;
        default:
          this.loginErrorText = 'Es ist ein Unbekannter Fehler aufgetreten';
      }
    }
  }

  createWebUser() {
    this._router.navigate(['signup'], {queryParams: {mail: this.mail}}).then();
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Ad, PriceType} from "../../interfaces/ad";
import {ApiService} from "../../services/api.service";
import {CreateReport} from "../../interfaces/report";
import {getDummyPicture} from "../../utils/dummyImages";

@Component({
    selector: 'kaz-card-report-ad',
    templateUrl: './card-report-ad.component.html',
    styleUrls: ['./card-report-ad.component.scss'],
    standalone: false
})
export class CardReportAdComponent {
    showReportAd: boolean = true;
    @Input() ad: Ad | null = null;
    @Output() closeReportAdOnEmit = new EventEmitter();

    report: CreateReport | null = null;

    reportMessage: string = '';
    reportReason: string = '';
    reportList: string[] = [
        'Falsche Kategorie',
        'Irreführung',
        'Unrealistischer Preis',
        'Spam',
        'Verbotener Artikel',
        'Andere Gründe'
    ];

    constructor(private _api: ApiService) {
    }

    closeReportAd() {
        this.showReportAd = false;
        this.closeReportAdOnEmit.emit();
    }

    async createReport() {
        try {
            if (this.ad !== null) {
                this.report = {
                    adId: this.ad.id,
                    reason: this.reportReason,
                    message: this.reportMessage,
                };
                const data = await this._api.createReport(this.report);
                this.showReportAd = false;
                this.closeReportAdOnEmit.emit();
            }
        } catch (err) {
            console.log(err);
        }
    }

  protected readonly getDummyPicture = getDummyPicture;
  protected readonly PriceType = PriceType;
}

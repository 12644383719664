import { Component } from '@angular/core';

@Component({
    selector: 'kaz-imprint',
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.scss'],
    standalone: false
})
export class ImprintComponent {

}

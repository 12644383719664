<div *ngIf="ad !== null" [class]="ad.validated ? 'bg-[#FEFEFE] ':'bg-[#F7F7F7]'"
     class="relative flex flex-col rounded-[6px] border border-[#F1F1F1] overflow-hidden md:rounded-[15px]">
  <div class="flex flex-row">
    <!-- Image -->
    <a
      class="relative flex h-[85px] w-[140px] items-center justify-center overflow-hidden md:h-[100px] md:w-[220px] md:cursor-pointer rounded-br-[15px]"
      routerLink="/entry/{{ad.id}}">
      <!-- BackgroundImage -->
      <img class="absolute left-0 top-0 z-0 w-full h-full object-cover blur"
           [src]="ad.images.length > 0 ? (ad.images[ad.titleImageIndex] | imageSource) : getDummyPicture(ad)"
           alt="Bild">
      <div class="absolute left-0 top-0 z-0 w-full h-full bg-black/50"></div>
      <img class="w-auto h-full z-0 object-cover md:rounded-br-[15px]"
           [src]="ad.images.length > 0 ? (ad.images[ad.titleImageIndex] | imageSource) : getDummyPicture(ad)"
           alt="Bild">
      <div *ngIf="ad.reserved"
           class="absolute flex top-0 left-0 w-full h-full bg-black/70 text-xs text-white font-semibold justify-center items-center md:text-base md:rounded-br-[15px]">
        <span>Reserviert</span>
      </div>
      <span *ngIf="ad.images.length > 0"
            class="absolute right-1 bottom-1 bg-[#183C5B] px-1.5 text-xs text-white font-semibold rounded-[2px] md:text-base md:px-3 md:py-1 md:rounded-[4px] md:rounded-br-[15px]">{{ ad.images.length }}</span>
    </a>
    <!-- lables -->
    <div class="relative flex flex-col p-4 w-full md:px-6">
      <a class="flex flex-row gap-2 items-center md:cursor-pointer" routerLink="/entry/{{ad.id}}">
        <span class="text-[#161D26] text-sm font-medium line-clamp-2 md:text-lg"
              [title]="ad.title">{{ ad.title }}</span>
        <fa-icon *ngIf="!ad.validated" class="text-[#F0811A]" icon="clock" title="Warte auf Freigabe"></fa-icon>
      </a>
      <span *ngIf="ad.category.subName !== 'Verschenken' && +ad.price > 0"
            class="absolute left-4 bottom-3 text-xs text-[#56BA90] font-medium md:left-6 md:text-xl md:font-semibold">€ {{ ad.price | currency:'EUR':'':'1.0-0':'de-DE' }} {{ ad.priceType === PriceType.VHB ? 'VHB' : '' }}</span>
      <div class="absolute right-3 bottom-3">
        <div (click)="reCreatAd(ad)"
             class="flex flex-row gap-2  px-3 grow justify-center bg-kaz-orange border border-kaz-orange text-white text-xs font-medium py-1 rounded-full md:cursor-pointer">
          <fa-icon icon="rotate-right"></fa-icon>
          <span>Wiederholen</span>
        </div>
      </div>
    </div>
  </div>
</div>

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Ad, PriceType} from "../../interfaces/ad";
import {CreateChat} from "../../interfaces/chat";
import {ApiService} from "../../services/api.service";
import {getDummyPicture} from "../../utils/dummyImages";
import {AuthenticationService} from "../../services/auth.service";
import {User} from "../../interfaces/user";
import {CipherType} from "../../interfaces/cipher";

@Component({
    selector: 'kaz-fast-message',
    templateUrl: './kaz-fast-message.component.html',
    styleUrls: ['./kaz-fast-message.component.scss'],
    standalone: false
})
export class KazFastMessageComponent {
  showFastMessage: boolean = true;
  @Input() ad: Ad | null = null;
  @Output() closeFastMessageOnEmit = new EventEmitter();

  chat: CreateChat | null = null;
  message: string = "";

  sendContactData: boolean = false;

  ownUser: User | null = null;

  constructor(private _api: ApiService,
              private _auth: AuthenticationService,) {
  }

  closeFastMessage() {
    this.showFastMessage = false;
    this.closeFastMessageOnEmit.emit();
  }

  async createMessage() {
    try {
      const claims = this._auth.getClaimsFromToken();
      if (this.ad !== null && claims !== null) {

        this.ownUser = await this._api.getOwnUser(claims.id);
        if (this.sendContactData){
          this.message += "\n\nInformationen über Interessent:\n";
          this.message += this.ownUser.firstname + " " + this.ownUser.lastname + "\n";
          // this.message += this.ownUser.phone + "\n";
          this.message += this.ownUser.mail;
        }

        this.chat = {
          recipientId: this.ad.authorId,
          adId: this.ad.id,
          message: this.message
        }
        await this._api.createChat(this.chat);
        this.showFastMessage = false;
      }
    } catch (err) {
      console.log(err);
    }
  }

  protected readonly getDummyPicture = getDummyPicture;
    protected readonly PriceType = PriceType;
  protected readonly CipherType = CipherType;
}

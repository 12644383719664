import {Component} from '@angular/core';
import {getCategoryList, getLocalityList, getSubCategoryList} from "../../utils/globals";
import {SmartAdType} from "../../interfaces/smartAd";

@Component({
    selector: 'kaz-fast-search',
    templateUrl: './kaz-fast-search.component.html',
    styleUrls: ['./kaz-fast-search.component.scss'],
    standalone: false
})
export class KazFastSearchComponent {
  showGeneral: boolean = false;
  showMobility: boolean = false;
  showLocality: boolean = false;

  localityList: string[] = getLocalityList();

  getFilteredSubCategory(mainCategoryName: string) {
    const subCategory = getSubCategoryList();
    return subCategory.filter(sCat => sCat.masterCategory === mainCategoryName);
  }

  protected readonly getCategoryList = getCategoryList;

  isExpandable(cat: string) {
    return cat === 'Dies & Das' || cat === 'Mobiles' || cat == 'Vermietungen';
  }

  changeActiveState(cat: string) {
    switch (cat) {
      case 'Dies & Das':
        this.showGeneral = !this.showGeneral;
        break;
      case 'Mobiles':
        this.showMobility = !this.showMobility;
        break;
      case 'Vermietungen':
        this.showLocality = !this.showLocality;
        break;
    }
  }

  showList(cat: string) {
    switch (cat) {
      case 'Dies & Das':
        return this.showGeneral;
      case 'Mobiles':
        return this.showMobility;
      case 'Vermietungen':
        return this.showLocality;
      default:
        return true
    }
  }

    protected readonly SmartAdType = SmartAdType;
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {NewUser} from "../../../interfaces/user";
import {AuthenticationService} from "../../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: 'kaz-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    standalone: false
})
export class SignupComponent implements OnInit, OnDestroy {
  // Regex
  uppercaseRegex = /[A-Z]/;
  digitRegex = /\d/;
  specialCharRegex = /[!?=|@#$%^()&*:;.,_+-]/;
  minLengthRegex = /.{8,}/

  subscription: Subscription | null = null;
  newUser: Partial<NewUser> | null = null;

  firstname: string = '';
  lastname: string = '';
  mail: string = '';
  password: string = '';
  repeatPassword: string = '';
  phone: string = '';

  isLoading: boolean = false;

  constructor(private _api: ApiService,
              private _auth: AuthenticationService,
              private _router: Router,
              private _aRouter: ActivatedRoute) {
  }

  ngOnInit() {
    this.isAuthenticated();
    this.subscription = this._aRouter.queryParams.subscribe(params => {
      this.mail = params['mail'] ?? '';
    })
  }

  ngOnDestroy() {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  isAuthenticated() {
    if (this._auth.isAuthenticated()) {
      this._router.navigate(['profile']).then();
    }
  }

  isDisabled() {
    if (!this.isLoading &&
      this.firstname.length > 0 &&
      this.lastname.length > 0 &&
      this.mail.charAt(this.mail.length - 1) !== '.' &&
      /[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}/.test(this.mail) && //Ist eine gültige mail
      this.password.length > 7 &&
      this.repeatPassword === this.password &&
      /[A-Z]/.test(this.password) &&      // Mindestens einen Großbuchstaben
      /\d/.test(this.password) &&         // Mindestens eine Zahl
      /[!?=|@#$%^()&*:;.,_+-]/.test(this.password)) { // Mindestens ein Sonderzeichen
      return false
    }
    return true
  }

  async signup() {
    try {
      this.isLoading = true;
      this.newUser = {
        firstname: this.firstname,
        lastname: this.lastname,
        mail: this.mail,
        phone: this.phone,
        password: this.password
      };
      const data = await this._api.signup(this.newUser);
      await this._router.navigate(['validate', data.user.id]);
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }

}

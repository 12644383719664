import { Pipe, PipeTransform } from '@angular/core';
import {environment} from "../../environments/environment";

@Pipe({
    name: 'imageSource',
    standalone: false
})
export class ImageSourcePipe implements PipeTransform {

  transform(value: string): string {
    return environment.apiBaseUrl +  "image/" + value;
  }

}

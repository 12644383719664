import { Component } from '@angular/core';

@Component({
    selector: 'kaz-fundamentals',
    templateUrl: './fundamentals.component.html',
    styleUrls: ['./fundamentals.component.scss'],
    standalone: false
})
export class FundamentalsComponent {

}

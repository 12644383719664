import { Component } from '@angular/core';
import {FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import localeDe from '@angular/common/locales/de';
import * as faLight from '@fortawesome/pro-light-svg-icons';
import * as faSolid from '@fortawesome/pro-solid-svg-icons';

import {registerLocaleData} from "@angular/common";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {

  constructor(library: FaIconLibrary, faConfig: FaConfig) {

    library.addIcons(
      faLight.faUser,
      faLight.faBars,
      faLight.faChevronLeft,
      faLight.faChevronUp,
      faLight.faChevronDown,
      faLight.faChevronRight,
      faLight.faXmark,
      faLight.faEnvelope,
      faLight.faMapLocationDot,
      faLight.faCamera,
      faLight.faCircleXmark,
      faLight.faMagnifyingGlass,
      faLight.faFilter,
      faLight.faHeart,
      faLight.faEye,
      faLight.faMessageLines,
      faLight.faCalendar,
      faLight.faShare,
      faLight.faLink,
      faLight.faExclamationCircle,
      faLight.faHashtag,
      faLight.faSpinnerThird,
      faLight.faTag,
      faLight.faCircleCheck,
      faLight.faPaperPlaneTop,
      faLight.faClock,
      faLight.faEmptySet,
      faLight.faCheck,
      faLight.faFaceSmileUpsideDown,
      faLight.faHourglassHalf,
      faLight.faHourglassEnd,
      faLight.faHourglassStart,
      faLight.faEye,
      faLight.faEyeSlash,
      faLight.faPlus,
      faLight.faLocationDot,
      faLight.faLightbulb,
      faLight.faBoxArchive,
      faLight.faCalendarClock,
      faLight.faCalendarExclamation,
      faLight.faRotateRight,

      faSolid.faCircleCheck,
      faSolid.faHeart,
      faSolid.faCircleDot,

      faLight.faLoader,
    )

    faConfig.defaultPrefix = 'fal';
    faConfig.fixedWidth = true;

    registerLocaleData(localeDe, 'de-DE');
  }
}

export function getCategoryList() {
  return [
    'Dies & Das',
    'Immobilien',
    'Job',
    'Mobiles',
  ];
}

export function getSubCategoryList() {
  return [
    {
      masterCategory: 'Dies & Das',
      name: 'Antiquitäten',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Baby & Kind',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Bekanntschaften',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Mode & Beauty',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Dienstleistungen',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Elektrogeräte',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Entlaufen/Entflogen',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Gebrauchtmöbel',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Gefunden',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Haus & Garten',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Haushaltsauflösung',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Landwirtschaftliche Erzeugnisse',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Musik, Filme, Bücher',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Sportzubehör',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Tiermarkt',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Tierschutz',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Tierzubehör',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Unterricht',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Verloren',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Verschenken',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Verschiedenes',
    },
    {
      masterCategory: 'Dies & Das',
      name: 'Zugelaufen/Zugeflogen',
    },
    {
      masterCategory: 'Immobilien',
      name: 'Private Grundstücke',
    },
    {
      masterCategory: 'Immobilien',
      name: 'Immobilien',
    },
    {
      masterCategory: 'Immobilien',
      name: 'Vermietungen',
    },
    {
      masterCategory: 'Job',
      name: 'Stellen',
    }, {
      masterCategory: 'Mobiles',
      name: 'Autoanhänger',
    }, {
      masterCategory: 'Mobiles',
      name: 'Autozubehör',
    },
    {
      masterCategory: 'Mobiles',
      name: 'Boote',
    },
    {
      masterCategory: 'Mobiles',
      name: 'Fahrräder',
    },
    {
      masterCategory: 'Mobiles',
      name: 'Geländewagen',
    },
    {
      masterCategory: 'Mobiles',
      name: 'Zweiräder',
    },
    {
      masterCategory: 'Mobiles',
      name: 'Motorradzubehör',
    },
    {
      masterCategory: 'Mobiles',
      name: 'Nutzfahrzeuge & LKW',
    },
    {
      masterCategory: 'Mobiles',
      name: 'Oldtimer',
    },
    {
      masterCategory: 'Mobiles',
      name: 'PKW',
    },
    {
      masterCategory: 'Mobiles',
      name: 'Wohnmobile & Wohnwägen',
    }
  ];
}

export function getLocalityList() {
  return [
    "Achern",
    "andere Orte",
    "Appenweier",
    "Bad Peterstal-Griesbach",
    "Bad Rippoldsau",
    "Berghaupten",
    "Biberach",
    "Bühl",
    "Durbach",
    "Ettenheim",
    "Fischerbach",
    "Friesenheim",
    "Gengenbach",
    "Gutach",
    "Haslach",
    "Hausach",
    "Hofstetten",
    "Hohberg",
    "Hornberg",
    "Kappel-Grafenhausen",
    "Kappelrodeck",
    "Kehl",
    "Kippenheim",
    "Lahr",
    "Lauf",
    "Lautenbach",
    "Mahlberg",
    "Meissenheim",
    "Mühlenbach",
    "Neuried",
    "Nordrach",
    "Oberharmersbach",
    "Oberkirch",
    "Oberwolfach",
    "Offenburg",
    "Ohlsbach",
    "Oppenau",
    "Ortenberg",
    "Ottenhöfen",
    "Renchen",
    "Rheinau",
    "Ringsheim",
    "Rust",
    "Sasbach",
    "Sasbachwalden",
    "Schenkenzell",
    "Schiltach",
    "Schuttertal",
    "Schutterwald",
    "Schwanau",
    "Seebach",
    "Seelbach",
    "Steinach",
    "Strasbourg",
    "Willstätt",
    "Wolfach",
    "Zell a.H."
  ];
}

export const dateReplacements = [
  // Weihnachten 2023
  {
    publicationOld: new Date(2023, 11, 27),
    publicationNew: new Date(2023, 11, 27, 0, 0, 0),
    expiryOld: new Date(2023, 11, 26),
    expiryNew: new Date(2023, 11, 22, 17, 0, 0)
  },
  // Neujahr 2024
  {
    publicationOld: new Date(2024, 0, 6),
    publicationNew: new Date(2024, 0, 5, 0, 0, 0),
    expiryOld: new Date(2024, 0, 4),
    expiryNew: new Date(2024, 0, 3, 17, 0, 0)
  },
  // Ostern 2024
  {
    publicationOld: new Date(2024, 2, 30),
    publicationNew: new Date(2024, 2, 30, 0, 0, 0),
    expiryOld: new Date(2024, 2, 28),
    expiryNew: new Date(2024, 2, 27, 16, 0, 0)
  },
  // Tag der Arbeit 2024
  {
    publicationOld: new Date(2024, 4, 1),
    publicationNew: new Date(2024, 3, 30, 0, 0, 0),
    expiryOld: new Date(2024, 3, 30),
    expiryNew: new Date(2024, 3, 29, 10, 0, 0)
  },
  // Christi Himmelfahrt 2024
  {
    publicationOld: new Date(2024, 4, 11),
    publicationNew: new Date(2024, 4, 11, 0, 0, 0),
    expiryOld: new Date(2024, 4, 9),
    expiryNew: new Date(2024, 4, 8, 15, 0, 0)
  },
  // Pfingsten 2024
  {
    publicationOld: new Date(2024, 5, 1),
    publicationNew: new Date(2024, 5, 1, 0, 0, 0),
    expiryOld: new Date(2024, 4, 30),
    expiryNew: new Date(2024, 4, 29, 15, 0, 0)
  },
  // Tag der Deutschen Einheit 2024
  {
    publicationOld: new Date(2024, 9, 5),
    publicationNew: new Date(2024, 9, 5, 0, 0, 0),
    expiryOld: new Date(2024, 9, 3),
    expiryNew: new Date(2024, 9, 2, 15, 0, 0)
  },
  // Allerheiligen 2024
  {
    publicationOld: new Date(2024, 10, 2),
    publicationNew: new Date(2024, 10, 2, 0, 0, 0),
    expiryOld: new Date(2024, 9, 31),
    expiryNew: new Date(2024, 9, 30, 15, 0, 0)
  },
  // Weihnachten 2024
  {
    publicationOld: new Date(2024, 11, 25),
    publicationNew: new Date(2024, 11, 24, 0, 0, 0),
    expiryOld: new Date(2024, 11, 24),
    expiryNew: new Date(2024, 11, 23, 10, 0, 0)
  },
  // Silvester 2024
  {
    publicationOld: new Date(2024, 11, 28),
    publicationNew: new Date(2024, 11, 28, 0, 0, 0),
    expiryOld: new Date(2024, 11, 26),
    expiryNew: new Date(2024, 11, 24, 10, 0, 0)
  }
];

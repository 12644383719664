import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'kaz-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy{
  title: string | null = null;
  description: string | null = null;
  show: boolean = false;

  private toastSubscription: Subscription | null = null;

  constructor(private _toast: ToastService) {

  }

  ngOnInit() {
    this.toastSubscription = this._toast.toastState$.subscribe(data =>{
      this.title = data.title;
      this.description = data.description;
      this.show = true;
      console.log("Toast erhalten:", data); // Debug-Ausgabe

      setTimeout(()=> this.show = false, 3000);
    })
  }

  ngOnDestroy() {
    this.toastSubscription?.unsubscribe();
  }
}

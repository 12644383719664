import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {User} from "../../../interfaces/user";
import {ApiService} from "../../../services/api.service";

@Component({
    selector: 'kaz-verify',
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.scss'],
    standalone: false
})
export class VerifyComponent implements OnInit{
webUser: User | null = null;
wUser: Partial<User> | null = null;
  constructor(private _route: ActivatedRoute,
              private _api: ApiService,
              private _router: Router,
              ) {
  }

  async ngOnInit() {
    this._route.paramMap.subscribe(params => {
      const id = params.get('id')!;
      const token = params.get('token')!;

      if (id !== null && token !== null){
        this.getWebUser(id, token).then();
      }
    });
  }

  async getWebUser(id: string, token: string) {
    try {
      this.webUser = await this._api.getOwnUser(id);
      if (!this.webUser.verified){
        await this.verifyUser(token);
      } else {
        this._router.navigate(['main']).then();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async verifyUser(token: string) {
    try {
      this.wUser = {
        id: this.webUser?.id,
        verified: true,
      };
      console.log(token)
      this.webUser = await this._api.verifyWebUser(this.wUser, token);

      setTimeout(() => {
        this._router.navigate(['login']).then();
      }, 4000);
    } catch (err){
      console.log(err);
    }
  }
}

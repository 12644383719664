import { Component } from '@angular/core';

@Component({
    selector: 'kaz-agb',
    templateUrl: './agb.component.html',
    styleUrls: ['./agb.component.scss'],
    standalone: false
})
export class AgbComponent {

}

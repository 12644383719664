<kaz-default-frame>
  <div *ngIf="(ad !== null && ad.validated && isInTimeSlot()) || (ad !== null && isOwnAd(ad.authorId))" class="mb-4">
    <div class="flex flex-col w-full">
      <!-- Back-Button -->
      <div
        class="hidden md:flex md:flex-row md:gap-0.5 md:w-[58px] md:mb-6 md:text-xs md:text-[#222222] md:justify-center md:cursor-pointer"
        (click)="goBack()">
        <fa-icon icon="chevron-left" size="sm"></fa-icon>
        <span>Zurück</span>
      </div>
      <div class="flex flex-col md:grid md:grid-cols-[1fr_353px] md:gap-8">
        <!-- links -->
        <div class="flex flex-col mb-16">
          <!-- Bild -->
          <div
            class="relative flex flex-row overflow-hidden overflow-x-auto w-auto h-[235px] bg-[#F5F5F5] justify-center items-center rounded md:h-[433px]">
            <!-- BackgroundImage -->
            <img class="absolute left-0 top-0 z-0 w-full h-full object-cover blur"
                 [src]="ad.images.length > 0 ? (ad.images[imageIndex] | imageSource) : getDummyPicture(ad)" alt="Bild">
            <div class="absolute left-0 top-0 z-0 w-full h-full bg-black/50"></div>
            <!-- Image -->
            <img class="w-auto h-full z-0 object-cover"
                 [src]="ad.images.length > 0 ? (ad.images[imageIndex] | imageSource) : getDummyPicture(ad)" alt="Bild">
            <!-- Image-Counter -->
            <div *ngIf="ad.images.length > 0"
                 class="absolute right-4 bottom-3 flex flex-row gap-1 text-white px-1.5 bg-[#183C5B] items-center rounded-[2px]">
              <fa-icon icon="camera" size="xs"></fa-icon>
              <span class="text-xs font-medium">{{ imageIndex + 1 }} / {{ ad.images.length }}</span>
            </div>
            <!-- Last Image -->
            <button *ngIf="imageIndex > 0"
                    class="absolute left-0 h-full top-1/2 transform ml-2 text-2xl font-bold -translate-y-1/2 bg-transparent border-none text-white md:hover:text-[#F0811A] md:text-5xl"
                    (click)="lastImage()">
              <fa-icon icon="chevron-left"></fa-icon>
            </button>

            <!-- Next image -->
            <button *ngIf="imageIndex + 1 < ad.images.length"
                    class="absolute right-0 h-full top-1/2 transform mr-2 text-2xl font-bold -translate-y-1/2 bg-transparent border-none text-white md:hover:text-[#F0811A] md:text-5xl"
                    (click)="nextImage()">
              <fa-icon icon="chevron-right"></fa-icon>
            </button>
          </div>
          <!-- Titel & Like -->
          <div class="grid grid-cols-[1fr_auto] mt-3 justify-center md:mt-6">
            <span class="text-base text-[#222222] font-medium md:text-xl md:font-semibold">{{ ad.title }}</span>
            <fa-icon class="hidden text-[#F0811A] md:hidden" icon="heart" size="lg"></fa-icon>
          </div>
          <!-- Preis -->
          <span *ngIf="ad.category.subName !== 'Verschenken' && +ad.price > 0"
                class="text-sm text-[#56BA90] font-semibold mt-1.5 md:text-xl md:font-semibold">€ {{ ad.price | currency:'EUR':'':'1.0-0':'de-DE' }} {{ ad.priceType === PriceType.VHB ? 'VHB' : '' }}</span>
          <!-- Locality -->
          <div *ngIf="ad.category.localityName !== ''"
               class="flex flex-row text-[#999999] text-xs font-medium gap-2 items-center mt-1.5">
            <fa-icon icon="location-dot"></fa-icon>
            <span class="mr-4">{{ ad.category.localityName }}</span>
          </div>
          <!-- Created & Views OwnUser-->
          <div *ngIf="isOwnAd(ad.authorId)"
               class="grid grid-cols-[auto_1fr] text-[#999999] text-xs font-medium gap-4 mt-1.5">
            <span>Erstellt am:</span>
            <span class="mr-4" title="Erstellt am {{ad.created | date}}">{{ ad.created | date: 'dd.MM.yyyy' }}</span>
            <span>In der Zeitung am:</span>
            <div class="grid grid-cols-2 gap-x-2 self-start md:grid-cols-6">
              <div *ngFor="let pub of ad.publicationDate">
                {{ pub | date }}
              </div>
            </div>
            <span>Online bis:</span>
            <span class="mr-4" title="Online bis {{ad.expired | date}}">{{ ad.expired | date: 'dd.MM.yyyy' }}</span>

          </div>
          <!-- Created & Views AllUser-->
          <div *ngIf="!isOwnAd(ad.authorId)"
               class="grid grid-cols-[auto_1fr] text-[#999999] text-xs font-medium gap-4 mt-1.5">
            <fa-icon icon="calendar"></fa-icon>
            <span class="mr-4" title="Erstellt am {{ad.created | date}}">{{ ad.created | date: 'dd.MM.yyyy' }}</span>
          </div>
          <!-- Divider -->
          <div class="w-full h-2 bg-[#FAFAFA] my-2.5 md:my-7"></div>
          <!-- Beschreibung-->
          <span class="text-xl text-[#222222] font-semibold md:mb-2">Beschreibung</span>
          <span class="text-sm text-[#222222] leading-6 whitespace-pre-wrap break-words"
                lang="de">{{ ad.description }}</span>
          <!-- Divider -->
          <div class="w-full h-2 bg-[#FAFAFA] my-2.5 md:my-7"></div>
          <!-- Anzeigen-ID & Anzeigeart -->
          <div class="grid grid-cols-[1fr_auto] text-sm my-2 gap-2 md:hidden">
            <span class="text-[#161D26]">Anzeigeart</span>
            <span *ngIf="ad.adType === AdType.OFFER" class="text-[#999999]">Angebot</span>
            <span *ngIf="ad.adType === AdType.WANTED" class="text-[#999999]">Gesuch</span>
            <span *ngIf="ad.adType === AdType.GOOD_DEED" class="text-[#999999]">Gute Tat</span>
            <span class="text-[#161D26]">Anzeigen-ID</span>
            <span class="text-[#999999]">{{ ad.cipher.number !== null ? ad.cipher.number : '-' }}</span>
          </div>
          <!-- Divider -->
          <div class="w-full h-2 bg-[#FAFAFA] my-2.5 md:hidden"></div>
          <!-- Share -->
          <div class="flex flex-col text-[#161D26] text-sm font-medium justify-center items-center my-3  md:hidden">
            <span class="mb-4">Diese Anzeige teilen</span>
            <div class="flex flex-row gap-2.5">
                    <span (click)="copyLink()"
                          class="flex h-10 w-10 rounded-full bg-[#999999] text-white items-center justify-center">
                        <fa-icon icon="link" size="lg"></fa-icon>
                    </span>
              <span (click)="shareLink()"
                    class="flex h-10 w-10 rounded-full bg-[#999999] text-white items-center justify-center">
                        <fa-icon icon="share" size="lg"></fa-icon>
                    </span>
            </div>
          </div>
          <!-- Divider -->
          <div class="w-full h-2 bg-[#FAFAFA] my-2.5 md:hidden"></div>
          <!-- Melden - Mobile-->
          <div (click)="showReportAd = true"
               class="flex flex-row h-10 gap-2 text-sm text-[#999999] justify-center items-center my-4 md:hidden">
            <fa-icon icon="circle-exclamation"></fa-icon>
            <span>Kleinanzeige melden</span>
          </div>
          <!-- Divider -->
          <div class="w-full h-2 bg-[#FAFAFA] my-2.5 md:hidden"></div>
          <!-- medium rectangle Desktop -->
          <kaz-smart-entry class="flex flex-row justify-center items-center h-72 md:hidden" [type]="SmartAdType.RECTANGLE"></kaz-smart-entry>
          <!-- Divider -->
          <div class="w-full h-2 bg-[#FAFAFA] my-2.5 md:hidden"></div>
          <!-- Neuste Anzeigen -->
          <div *ngIf="newestAds !== null" class="flex flex-col gap-4">
            <span class="text-xl text-[#222222] font-semibold">Neuste Anzeige</span>
            <div *ngFor="let newestAd of newestAds.ads">
              <kaz-card-default [ad]="newestAd"></kaz-card-default>
            </div>
          </div>
        </div>
        <!-- rechts - DESKTOP ONLY -->
        <div class="hidden md:flex md:flex-col md:gap-4 md:w-full">
          <!-- Nachricht -->
          <div *ngIf="!isOwnAd(ad.authorId)" (click)="openFastMessage()" class="text-center w-full md:cursor-pointer">
            <div
              class="flex flex-row gap-2 bg-[#F0811A] w-full h-12 text-white text-base font-semibold rounded-full items-center justify-center">
              <fa-icon icon="message-lines"></fa-icon>
              <span>Nachricht</span>
            </div>
          </div>
          <!-- Favoriten -->
          <div class=" hidden text-center w-full md:cursor-pointer">
            <div
              class="flex flex-row gap-2 bg-white w-full h-12 text-[#F0811A] text-base font-semibold border border-[#F0811A] rounded-full items-center justify-center">
              <fa-icon icon="heart"></fa-icon>
              <span>Zu Favoriten hinzufügen</span>
            </div>
          </div>
          <!-- Link kopieren -->
          <div class="text-center w-full md:cursor-pointer">
            <div (click)="copyLink()"
              class="flex flex-row gap-2 bg-white w-full h-12 text-[#F0811A] text-base font-semibold border border-[#F0811A] rounded-full items-center justify-center hover:bg-kaz-orange/5">
              <fa-icon icon="link"></fa-icon>
              <span>Link kopieren</span>
            </div>
          </div>
          <!-- Erneut Aufgeben -->
          <div *ngIf="isOwnAd(ad.authorId) && isAfterTimeSlot()" class="text-center w-full md:cursor-pointer"
               (click)="reCreatAd(ad)">
            <div
              class="flex flex-row gap-2 bg-kaz-orange w-full h-12 text-white text-base font-semibold border border-kaz-orange rounded-full items-center justify-center">
              <fa-icon icon="rotate-right"></fa-icon>
              <span>Anzeige erneut aufgeben</span>
            </div>
          </div>
          <!-- Divider -->
          <div class=" flex w-full h-1 gap-4 bg-[#FAFAFA]"></div>
          <div class="flex flex-col gap-6">
            <!-- Anzeigen-ID & Anzeigeart -->
            <div class="grid grid-cols-[1fr_auto] gap-2 text-sm">
              <span class="text-[#161D26]">Anzeigeart</span>
              <span *ngIf="ad.adType === AdType.OFFER" class="text-[#999999] text-end">Angebot</span>
              <span *ngIf="ad.adType === AdType.WANTED" class="text-[#999999] text-end">Gesuch</span>
              <span *ngIf="ad.adType === AdType.GOOD_DEED" class="text-[#999999] text-end">Gute Tat</span>
              <span class="text-[#161D26]">Anzeigen-ID</span>
              <span class="text-[#999999] text-end">{{ ad.cipher.number !== null ? ad.cipher.number : '-' }}</span>
              <span class="text-[#161D26]">Kategorie</span>
              <span class="text-[#999999] whitespace-pre-wrap text-end" translate="no">{{ ad.category.name }}
                | {{ ad.category.subName }}</span>
            </div>
            <!-- Melden - Desktop -->
            <div (click)="showReportAd = true"
                 class="flex flex-row h-10 gap-2 text-sm text-[#999999] justify-center items-center md:cursor-pointer  hover:text-[#F0811A]">
              <fa-icon icon="circle-exclamation"></fa-icon>
              <span>Kleinanzeige melden</span>
            </div>
            <!-- Divider -->
            <div class=" flex w-full h-1 bg-[#FAFAFA]"></div>
            <!-- medium rectangle Desktop -->
            <kaz-smart-entry class="flex flex-row justify-center items-center h-72" [type]="SmartAdType.RECTANGLE"></kaz-smart-entry>
          </div>
        </div>
      </div>
    </div>


    <!-- Chat-Btn -->
    <div *ngIf="!isOwnAd(ad.authorId)" (click)="openFastMessage()"
         class="fixed bottom-6 left-0 right-0 p-4 text-center md:hidden">
      <div
        class="flex flex-row gap-2 bg-[#F0811A] w-full h-12 text-white text-base font-semibold rounded-full items-center justify-center">
        <fa-icon icon="message-lines"></fa-icon>
        <span>Nachricht</span>
      </div>
    </div>
  </div>
  <!-- If is expired -->
  <div *ngIf="ad !== null && !isInTimeSlot() && !isOwnAd(ad.authorId)"
       class="flex flex-col mb-4 h-full items-center justify-center">
    <fa-icon class="text-[#F0811A] my-20" icon="hourglass-half" size="10x"></fa-icon>
    <span class="mx-8 text-center font-semibold text-xl text-[#A7A7A7]">
      Diese Anzeige befindet sich außerhalb des festgelegten Erscheinungszeitpunkts und ist daher zum gegenwärtigen Zeitpunkt nicht verfügbar.
    </span>
  </div>

</kaz-default-frame>
<kaz-fast-message *ngIf="showFastMessage" [ad]="ad"
                  (closeFastMessageOnEmit)="showFastMessage = false"></kaz-fast-message>
<kaz-card-report-ad *ngIf="showReportAd" [ad]="ad"
                    (closeReportAdOnEmit)="showReportAd = false"></kaz-card-report-ad>

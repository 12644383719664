<kaz-default-frame [scrollable]="!showAdPreview">
  <div class="flex flex-col md:grid md:grid-cols-[auto_1fr] md:gap-y-8 md:gap-x-24 md:mb-[118px]">
    <div class="flex w-full pb-4 items-center justify-center md:hidden">
      <span class="text-base font-medium text-[#222222]">Inserieren</span>
    </div>
    <!-- Bilder-Label-->
    <span class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium">Bilder</span>
    <!-- Bilder-Galerie -->
    <div class="flex flex-col w-full text-center">
      <!-- Bild -->
      <div class="flex flex-col mb-2.5 bg-[#F5F5F5] h-[254px] rounded md:hidden">
        <div *ngIf="images.length <= 0" class="flex flex-col py-10 gap-5 items-center justify-center">
          <img class="w-[210px]" src="../../../../assets/images/404.svg" alt="Bilder leer">
          <span
            class="text-base font-medium text-[#A7A7A7] px-11">Bisher noch keine Bilder vorhanden, füge sie gerne hinzu.</span>
        </div>
        <div *ngIf="images.length >= 1" class="flex h-full items-center justify-center rounded overflow-hidden">
          <img class="h-full w-auto object-cover" [src]="images[imageIndex]" alt="Titelbild">
        </div>
      </div>
      <!-- Karusell -->
      <div class="flex flex-row gap-2.5 overflow-x-auto max-w-full">
        <div *ngFor="let image of images" class="mb-4 h-[72px] md:h-[144px]">
          <div class="relative" (click)="setImageIndex(image)">
            <!-- Bild löschen -->
            <fa-icon *ngIf="imageIndex === images.indexOf(image)"
                     class="absolute h-6 w-6 top-0 right-0 text-[#F0811A] md:hidden"
                     size="2xs" icon="circle-xmark"
                     (click)="deleteImage(image)"></fa-icon>
            <fa-icon *ngIf="imageIndex === images.indexOf(image)"
                     class="absolute hidden h-6 w-6 top-4 right-4 text-[#F0811A] md:flex md:cursor-pointer"
                     size="xl" icon="circle-xmark"
                     (click)="deleteImage(image)"></fa-icon>
            <!-- Titelbild setzen -->
            <div *ngIf="imageIndex === images.indexOf(image) && titleImageIndex !== images.indexOf(image)"
                 (click)="setAsTitleImage(image)"
                 class="absolute flex bottom-1 left-1/2 h-4 w-14 transform -translate-x-1/2 p-1 bg-black/70 text-white text-[8px] font-semibold rounded-full justify-center items-center md:text-xs md:h-6 md:w-24 md:cursor-pointer md:bottom-2">
              Titelbild
            </div>
            <div
              class="relative flex overflow-hidden w-[72px] h-[72px] bg-[#F5F5F5] rounded justify-center items-center md:h-[144px] md:w-[144px]"
              [class]="titleImageIndex === images.indexOf(image) ? 'border-[2px] border-[#F0811A] md:border-[5px]' : 'border border-[#F1F1F1]'">
              <!-- BackgroundImage -->
              <img class="absolute left-0 top-0 z-0 w-full h-full object-cover blur"
                   [src]="image" alt="Bild">
              <div class="absolute left-0 top-0 z-0 w-full h-full bg-black/50"></div>
              <img class="w-auto h-full z-0 object-cover" [src]="image" alt="Bild">
            </div>
          </div>
        </div>
      </div>
      <span *ngIf="isWrongImageTag" class="flex text-sm text-[#F0811A] font-semibold mb-3">
        Folgende Bildformate sind zulässig: png, jpg, jpeg.
      </span>
      <span *ngIf="isBigImageFile" class="flex text-sm text-[#F0811A] font-semibold mb-3">
        Bild muss kleiner als 12MB sein.
      </span>
      <div class="flex flex-col gap-8 md:flex-row items-center">
        <!-- Bilder Button -->
        <div
          class="flex flex-row gap-2 w-full rounded-full border border-kaz-blue h-12 items-center justify-center md:max-w-[343px]"
          (click)="onSelectImage()"
          [class]="images.length < 10 ? 'md:cursor-pointer bg-white text-kaz-blue' : 'pointer-events-none border-kaz-gray bg-kaz-gray text-white'">
          <fa-icon icon="camera" size="lg"></fa-icon>
          <span class="text-base font-semibold">Bilder hinzufügen</span>
          <input type="file" style="display: none" #imageInput accept=".png, .jpg, .jpeg"
                 (change)="onFileSelected($event)">
        </div>
        <!-- Alte Bilder übernehmen -->
        <div *ngIf="previousAdId" class="flex gap-3 flex-row">
          <input type="checkbox" id="oldImage" name="chat" [checked]="transferOldImages" (change)="changeTransferOldImages($event)"/>
          <label class="font-medium text-sm text-kaz-black-text" for="oldImage">Vorhandene Bilder übernehmen</label>
        </div>
      </div>
      <div class="flex flex-row gap-2 text-sm text-kaz-dark-gray font-medium px-2 mt-1.5 items-center">
        <fa-icon class="text-kaz-orange" icon="lightbulb" size="lg"></fa-icon>
        <span class="flex text-start">Max. 10 Bilder (12 MB). Anzeige erscheint auch in der Tageszeitung mit Bild – ganz ohne Mehrkosten.</span>
      </div>
    </div>
    <!-- Divider -->
    <div class="h-2 w-full bg-[#FAFAFA] my-5 md:hidden"></div>
    <!-- Kategorie-Label-->
    <span class="hidden md:flex md:text-[#161D26] mt-9 md:text-lg md:font-medium" translate="no">Kategorie</span>
    <!-- AdType & Category -->
    <div class="flex flex-col gap-4">
      <!-- AdType -->
      <div class="flex flex-row gap-4 items-center">
        <!-- Radio Btn -->
        <div class="flex flex-row gap-1.5 text-sm text-[#161D26] font-medium" (change)="onAdTypeChange(AdType.OFFER)">
          <input type="radio" [id]="AdType.OFFER" [name]="AdType.OFFER" [value]="AdType.OFFER"
                 [checked]="adType === AdType.OFFER">
          <label [for]="AdType.OFFER">Angebot</label>
        </div>
        <!-- Radio Btn -->
        <div class="flex flex-row gap-1.5 text-sm text-[#161D26] font-medium" (change)="onAdTypeChange(AdType.WANTED)">
          <input type="radio" [id]="AdType.WANTED" [name]="AdType.WANTED" [value]="AdType.WANTED"
                 [checked]="adType === AdType.WANTED">
          <label [for]="AdType.WANTED">Gesuch</label>
        </div>
        <!-- Radio Btn -->
        <div class="flex flex-row gap-1.5 text-sm text-[#161D26] font-medium" (change)="onAdTypeChange(AdType.GOOD_DEED)">
          <input type="radio" [id]="AdType.GOOD_DEED" [name]="AdType.GOOD_DEED" [value]="AdType.GOOD_DEED"
                 [checked]="adType === AdType.GOOD_DEED">
          <label [for]="AdType.GOOD_DEED">Gute Tat</label>
        </div>
      </div>
      <!-- Kategorien -->
      <select [(ngModel)]="categoryName" id="category" (change)="setCategoryHint()"
              class="leading-4 h-12 px-4 mb-5 text-base font-medium border border-[#999999] bg-[#FAFAFA] rounded-[6px] md:mb-0 md:cursor-pointer"
              [class]="categoryName === '' ? 'text-[#9ca3af]' : 'text-[#222222]'">
        <option value="" disabled selected>Wählen Sie eine Kategorie</option>
        <option *ngFor="let category of getCatList()" [value]="category" translate="no">{{ category }}</option>
      </select>
    </div>
    <!-- Subkategorie-Label-->
    <span *ngIf="categoryName !== ''"
          class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium">Unterkategorie</span>
    <!-- Subkegorien -->
    <select *ngIf="categoryName !== ''" [(ngModel)]="subCategoryName" id="subcategory"
            (ngModelChange)="localityName = '' "
            class="leading-4 h-12 px-4 mb-5 text-base font-medium border border-[#999999] bg-[#FAFAFA] rounded-[6px] md:cursor-pointer md:mb-0"
            [class]="subCategoryName === '' ? 'text-[#9ca3af]' : 'text-[#222222]'">
      <option value="" disabled selected>Wählen Sie die Unterkategorie</option>
      <option *ngFor="let subCategory of getFilteredSubCategoryList()"
              [value]="subCategory.name" translate="no">{{ subCategory.name }}
      </option>
    </select>
    <!-- Locality-Label-->
    <span *ngIf="subCategoryName === 'Vermietungen'"
          class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium">Ortschaft</span>
    <!-- Locality -->
    <select *ngIf="subCategoryName === 'Vermietungen'" [(ngModel)]="localityName" id="locality"
            class="leading-4 h-12 px-4 text-base font-medium border border-[#999999] bg-[#FAFAFA] rounded-[6px] md:cursor-pointer"
            [class]="localityName === '' ? 'text-[#9ca3af]' : 'text-[#222222]'">
      <option value="" disabled selected>Wählen Sie die Ortschaft</option>
      <option *ngFor="let locality of localityList"
              [value]="locality">{{ locality }}
      </option>
    </select>
    <!-- Divider -->
    <div class="h-2 w-full bg-[#FAFAFA] my-5 md:hidden"></div>
    <!-- Titel-Label-->
    <span class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium">Titel</span>
    <!-- Title & TitleInfo -->
    <div class="flex flex-col">
      <!-- Title -->
      <kaz-input [(value)]="title" [maxLength]="40" placeholder="Titel *"></kaz-input>
      <div class="grid grid-cols-[1fr_auto] text-sm text-kaz-dark-gray font-medium px-2 mt-1.5 gap-4">
        <div class="flex flex-row gap-2 items-center">
          <fa-icon class="text-kaz-orange" icon="lightbulb" size="lg"></fa-icon>
          <span>Geben Sie Ihrer Anzeige einen aussagekräftigen Titel, der das Interesse Ihrer Zielgruppe weckt.</span>
        </div>
        <span>{{ title.length }}/40</span>
      </div>
    </div>
    <!-- Divider -->
    <div class="h-2 w-full bg-[#FAFAFA] my-5 md:hidden"></div>
    <!-- Beschreibungs-Label-->
    <span class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium">Beschreibung</span>
    <!-- Beschreibung & BeschreibungsInfo -->
    <div class="flex flex-col">
      <!-- Beschreibung -->
      <textarea [(ngModel)]="description" maxlength="300"
                class="p-4 w-full h-[205px] leading-6 resize-none text-base text-[#161D26] font-medium border border-kaz-dark-gray bg-[#FAFAFA] rounded-[6px]"
                placeholder="Beschreibung">
    </textarea>
      <div class="grid grid-cols-[1fr_auto] text-sm text-kaz-dark-gray font-medium px-2 mt-1.5 gap-4">
        <div class="flex flex-row gap-2 items-center">
          <fa-icon class="text-kaz-orange" icon="lightbulb" size="lg"></fa-icon>
          <span>Beschreiben Sie Ihren Artikel möglichst präzise und geben Sie Ihre Kontaktdaten an.</span>
        </div>
        <span>{{ description.length }}/300</span>
      </div>
    </div>

    <!-- Chiffre-Label-->
    <span *ngIf="adType !== AdType.GOOD_DEED"
          class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium">Chiffre</span>
    <!-- Preis & Chiffre -->
    <div *ngIf="adType !== AdType.GOOD_DEED">
      <!-- Divider -->
      <div class="h-2 w-full bg-[#FAFAFA] my-5 md:hidden"></div>
      <!-- Chiffre -->
      <div
        class="flex gap-3 pb-4 flex-col items-center justify-center font-medium border border-[#999999] rounded-[6px]">
        <div (click)="showCipher = !showCipher"
             class="flex flex-row gap-3 pt-4 w-full items-center justify-center text-[#224361]">
          <span class="font-medium text-sm">Chiffre buchen</span>
          <span><fa-icon [icon]="showCipher ? 'chevron-up' : 'chevron-down'"></fa-icon></span>
        </div>
        <div [@slide]="showCipher" class="flex flex-col gap-4 items-center" *ngIf="showCipher">
          <div class="flex flex-row gap-4">
            <!-- Nachricht -->
            <div class="flex gap-1.5 flex-row" (click)="onAdCipherTypeChange(CipherType.CHAT)">
              <input type="checkbox" [id]="CipherType.CHAT" [name]="CipherType.CHAT" [value]="CipherType.CHAT"
                     [checked]="cipherType === CipherType.CHAT"/>
              <label class="font-medium text-sm" [for]="CipherType.CHAT">Chat</label>
            </div>
            <!-- Brief -->
            <div class="flex gap-1.5 flex-row" (click)="onAdCipherTypeChange(CipherType.LETTER)">
              <input type="checkbox" [id]="CipherType.LETTER" [name]="CipherType.LETTER" [value]="CipherType.LETTER" [checked]="cipherType === CipherType.LETTER"/>
              <label class="font-medium text-sm" [for]="CipherType.LETTER">Post</label>
            </div>
            <!-- Abholung -->
            <div class="flex gap-1.5 flex-row" (click)="onAdCipherTypeChange(CipherType.OFFICE)">
              <input type="checkbox" [id]="CipherType.OFFICE" [name]="CipherType.OFFICE" [value]="CipherType.OFFICE" [checked]="cipherType === CipherType.OFFICE"/>
              <label class="font-medium text-sm" [for]="CipherType.OFFICE">
                <span>Abholung Kundencenter </span>
                <span class="text-[#D2D2D2]">- nur in Offenburg</span>
              </label>
            </div>
          </div>
          <div [@slide]="showCipher" *ngIf="cipherType === CipherType.LETTER" class="flex flex-col gap-4">
            <kaz-input [(value)]="firstnameCipher" placeholder="Vorname"></kaz-input>
            <kaz-input [(value)]="lastnameCipher" placeholder="Nachname"></kaz-input>
            <div class="flex flex-row gap-2">
              <kaz-input [(value)]="streetCipher" class="grow" placeholder="Straße"></kaz-input>
              <kaz-input [(value)]="houseNumberCipher" class="w-20" placeholder="Nr"></kaz-input>
            </div>
            <div class="grid grid-cols-2 gap-2">
              <kaz-input [(value)]="zipCipher" placeholder="PLZ"></kaz-input>
              <kaz-input [(value)]="cityCipher" placeholder="Stadt"></kaz-input>
            </div>
            <div class="grid grid-cols-2 gap-2">
              <kaz-button (onClick)="fillCipher()" [disabled]="ownUser?.cipher?.firstname === null"
                          buttonType="bordered" text="auto. ausfüllen"></kaz-button>
              <kaz-button (onClick)="clearCipher()" [hidden]="areCipherEmpty()" buttonType="bordered"
                          text="leeren"></kaz-button>
            </div>
          </div>
          <div [@slide]="showCipher" *ngIf="cipherType === CipherType.OFFICE" class="flex flex-col gap-4 w-full">
            <kaz-input [(value)]="firstnameCipher" placeholder="Vorname"></kaz-input>
            <kaz-input [(value)]="lastnameCipher" placeholder="Nachname"></kaz-input>
            <div class="grid grid-cols-2 gap-2">
              <kaz-button (onClick)="fillCipher()" [disabled]="ownUser?.cipher?.firstname === null"
                          buttonType="bordered" text="auto. ausfüllen"></kaz-button>
              <kaz-button (onClick)="clearCipher()" [hidden]="areCipherEmpty()" buttonType="bordered"
                          text="leeren"></kaz-button>
            </div>
          </div>
        </div>

      </div>
      <span *ngIf="cipherType !== CipherType.NONE && cipherType !== CipherType.CHAT"
            class="text-xs text-[#999999] font-medium pl-2 mt-1.5">Gebührenpflichtig - 8,00 €</span>
      <span *ngIf="cipherType === CipherType.CHAT"
            class="text-xs text-[#999999] font-medium pl-2 mt-1.5">Kostenlos</span>
    </div>
    <!-- Preis-Label-->
    <span *ngIf="adType !== AdType.GOOD_DEED" class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium md:mt-9">Preis</span>
    <!-- Preistyp & Preis & PreisInfo -->
    <div *ngIf="adType !== AdType.GOOD_DEED" class="flex flex-col">
      <!-- Divider -->
      <div class="h-2 w-full bg-[#FAFAFA] my-5 md:hidden"></div>
      <!-- Radio Buttons - Preistyp -->
      <div class="flex flex-row gap-4 items-center mb-4">
        <!-- Radio Btn -->
        <div class="flex flex-row gap-1.5 text-sm text-[#161D26] font-medium"
             (click)="onAdPriceTypeChange(PriceType.FP)">
          <input type="radio" id="fixedPrice" name="price" [checked]="priceType === PriceType.FP">
          <label for="fixedPrice">Festpreis</label>
        </div>
        <!-- Radio Btn -->
        <div class="flex flex-row gap-1.5 text-sm text-[#161D26] font-medium"
             (click)="onAdPriceTypeChange(PriceType.VHB)">
          <input type="radio" id="basisPrice" name="price" [checked]="priceType === PriceType.VHB">
          <label for="basisPrice">Verhandlungsbasis</label>
        </div>
      </div>
      <!-- Price -->
      <div class="relative flex items-center w-full md:max-w-[343px]">
        <span class="absolute right-5 text-base font-medium">{{ priceType === PriceType.FP ? '€' : 'VHB €' }}</span>
        <input
          class="w-full h-12 pl-4 pr-20 leading-4 text-base font-medium border border-[#999999] bg-[#FAFAFA] rounded-[6px]"
          maxlength="10"
          title="Preis" type="text" [(ngModel)]="price" (input)="formatInput($event)" (focusin)="checkPrice($event)"
          (keydown)="allowOnlyNumbers($event)">
      </div>
    </div>
    <!-- Divider -->
    <div class="h-2 w-full bg-[#FAFAFA] my-5 md:hidden"></div>
    <!-- Veröffentlichungs-Label-->
    <span class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium">Zeitungsinserat</span>
    <!-- Veröffentlichungen -->
    <div class="flex flex-col">
      <div class="flex items-center justify-center mb-5">
        <img class="w-[285px]" src="../../../../assets/images/newspaper_logo.png" alt="Mittelbadische Presse">
      </div>
      <span (click)="showAdInfo = true"
            class="flex text-xs text-[#A7A7A7] underline mb-5 justify-center md:cursor-pointer">Infos zu Ihrem Zeitungsinserat</span>
      <span class="flex text-sm text-[#A7A7A7] mb-2 justify-center">Erscheinungstage wählen</span>
      <div *ngFor="let date of allAvailableDates">
        <div (click)="toggleDate(date.publicationDate.toISOString())"
             class=" flex flex-col gap-2 rounded-[6px] w-full p-5 mb-2.5 text-center text-sm border md:text-base md:cursor-pointer"
             [class]="publicationDates.includes(date.publicationDate.toISOString()) ? 'bg-[#F0811A] text-white font-semibold border-[#F0811A]':'text-[#161D26] font-medium border-[#F1F1F1] md:hover:bg-[#F1F1F1]/70'">
          <span>{{ getWeekDayName(date.publicationDate) }}, den {{ date.publicationDate | date: 'dd.MM.yyyy' }}</span>
          <span
            class="text-xs font-medium pl-2 md:text-sm"
            [class]="publicationDates.includes(date.publicationDate.toISOString()) ?'text-white':'text-[#999999]'">Anzeigenschluss: {{ getWeekDayName(date.expirationDate) }}
            , den {{ (date.expirationDate | date: 'dd.MM.yyyy, HH:mm') + ' Uhr' }}</span>
        </div>
      </div>
      <div *ngIf="publicationDateAmount <= 8" (click)="addPublicationDateAmount()"
           class="flex flex-col w-full p-4 rounded-[6px] text-center text-sm border text-[#F0811A] border-[#F1F1F1] md:text-base md:cursor-pointer md:hover:bg-[#F1F1F1]/70">
        <fa-icon icon="plus" size="xl"></fa-icon>
      </div>
    </div>
    <!-- Divider -->
    <div class="h-2 w-full bg-[#FAFAFA] my-5 md:hidden"></div>
  </div>
  <kaz-button *ngIf="!isLoading" (onClick)="showAdPreview = true" [disabled]="isFormIncomplete()" width="343px"
              class="mb-6"
              [text]="(placementFee + cipherFee === 0) ? 'Anzeige veröffentlichen' : ('Für ' + (placementFee + cipherFee | currency:'EUR':'symbol':'1.2-2') + ' veröffentlichen')">
  </kaz-button>
  <div *ngIf="isLoading"
       class="flex items-center justify-center w-full h-[3rem] mb-6 text-white bg-[#F0811A] font-semibold text-base rounded-full">
    <fa-icon icon="spinner-third" class="animate-spin"></fa-icon>
  </div>
  <kaz-ad-info *ngIf="showAdInfo" (closeInfoOnEmit)="showAdInfo = false"></kaz-ad-info>
</kaz-default-frame>

<!-- Preview -->
<div *ngIf="showAdPreview" class="fixed top-0 left-0 w-full h-full bg-black/70">
  <div
    class="absolute top-0 left-0 flex flex-col p-4 w-full h-full bg-white gap-4 overflow-y-auto md:h-auto md:max-h-screen md:gap-x-16 md:gap-y-8 md:bg-[#FAFAFA] md:w-[1080px] md:p-8 md:pl-16 md:rounded-[20px] md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2">
    <div class="flex flex-col md:flex-row md:gap-16 md:border-b md:border-[#EFEFEF]">
      <!-- Rechnungsbetrag -->
      <div class="flex flex-col md:grow">
        <!-- Header -->
        <div class="relative flex flex-row h-12 mb-4 justify-center items-center md:mb-8">
          <div class="flex flex-row absolute left-0 text-xs text-[#224261] justify-center md:hidden"
               (click)="showAdPreview = false">
            <fa-icon icon="chevron-left"></fa-icon>
            <span>Zurück</span>
          </div>
          <span class="grow text-base text-center text-[#222222] font-medium md:text-start md:text-xl md:font-semibold">Rechnungsbetrag</span>
        </div>
        <!-- Tabelle -->
        <div
          class="grid grid-cols-3 pb-2 text-xs text-[#222222] justify-center border-b border-[#EFEFEF] md:text-base md:pb-4">
          <span>Artikel</span>
          <span *ngIf="cipherType !== CipherType.NONE" class="text-center">Menge/Type</span>
          <span *ngIf="cipherType === CipherType.NONE" class="text-center">Menge</span>
          <span class="text-end">Preis</span>
        </div>
        <div *ngIf="publicationDates.length > 0"
             class="grid grid-cols-3 pt-2 text-sm text-[#222222] font-medium justify-center md:pt-4 md:text-lg">
          <span>Servicepauschale</span>
          <span class="text-center">{{ publicationDates.length }}</span>
          <span *ngIf="adType !== AdType.GOOD_DEED"
                class="text-end">{{ (publicationDates.length * 12.5) | currency:'EUR':'symbol':'1.2-2' + '' }}</span>
          <span *ngIf="adType === AdType.GOOD_DEED" class="text-end">{{ 0 | currency:'EUR':'symbol':'1.2-2' + '' }}</span>

        </div>
        <div *ngIf="cipherType !== CipherType.NONE"
             class="grid grid-cols-3 pt-2 text-sm text-[#222222] font-medium justify-center md:pt-4 md:text-lg">
          <span>Chiffre</span>
          <span *ngIf="cipherType === CipherType.LETTER" class="capitalize text-center">Brief</span>
          <span *ngIf="cipherType === CipherType.OFFICE" class="capitalize text-center">Kundencenter</span>
          <span *ngIf="cipherType === CipherType.CHAT" class="capitalize text-center">Chat</span>
          <span class="text-end">{{ cipherFee | currency:'EUR':'symbol':'1.0-0' }}</span>
        </div>
        <!-- Divider -->
        <div *ngIf="placementFee + cipherFee > 0" class="mt-2 mb-5 border-t border-[#EFEFEF]"></div>
        <!-- Preis -->
        <div class="grid grid-cols-[1fr_auto] text-[#F0811A] items-center mb-8">
          <span class="text-base font-semibold md:text-xl">Gesamtsumme</span>
          <span
            class="text-lg font-semibold md:text-2xl">{{ placementFee + cipherFee | currency:'EUR':'symbol':'1.2-2' }}</span>
        </div>
        <!-- Rechnungsadresse -->
        <span class="text-base text-center text-[#222222] font-medium mb-4 md:text-start md:text-xl md:font-semibold">Rechnungsadresse</span>
        <div
          class="flex flex-col gap-4 md:grid md:grid-cols-[auto_1fr] md:items-center md:gap-x-12 md:border-b md:border-[#EFEFEF] md:pb-4">
          <!-- Name-Label-->
          <span class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium">Name</span>
          <!-- Firstname & Lastname-->
          <div class="flex flex-col gap-4 md:grid md:grid-cols-2">
            <kaz-input [(value)]="billingAddress.firstname" [maxLength]="40" placeholder="Vorname *"></kaz-input>
            <kaz-input [(value)]="billingAddress.lastname" [maxLength]="40" placeholder="Nachname *"></kaz-input>
          </div>

          <!-- Address-Label-->
          <span class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium">Straße & Nr.</span>
          <!-- Street & Housenumber-->
          <div class="gap-4 grid grid-cols-[auto_8rem] w-full">
            <kaz-input [(value)]="billingAddress.street" [maxLength]="40" placeholder="Straße *"></kaz-input>
            <kaz-input [(value)]="billingAddress.number" [maxLength]="40" placeholder="Nr *"></kaz-input>
          </div>

          <span class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium">PLZ & Stadt</span>
          <!-- Zip & city -->
          <div class="gap-4 grid grid-cols-[8rem_auto] w-full">
            <kaz-input [(value)]="billingAddress.zip" [maxLength]="40" placeholder="PLZ *"></kaz-input>
            <kaz-input [(value)]="billingAddress.city" [maxLength]="40" placeholder="Stadt *"></kaz-input>
          </div>

        </div>
        <!-- Divider -->
        <div class="h-2 w-full bg-[#FAFAFA] my-5 md:hidden"></div>
      </div>
      <!-- Vorschau -->
      <div class="flex flex-col">
        <!-- Header -->
        <div
          class="flex mb-4 text-base text-[#222222] font-medium justify-center md:justify-start md:text-xl md:font-semibold md:mb-8 md:h-12 md:items-center">
          <span>Vorschau Ihrer Zeitungsanzeige</span>
        </div>
        <!-- PreviewBody -->
        <div class="flex flex-col px-12 py-6 md:mb-5 border border-[#F1F1F1] bg-[#FEFEFE] rounded-[2px] md:w-[342px]">
          <!-- Titlebild -->
          <div *ngIf="images.length > 0" class="flex flex-col mb-2.5 bg-[#F5F5F5] h-[166px] rounded">
            <div class="flex h-full items-center justify-center rounded overflow-hidden">
              <img class="h-full w-auto object-cover" [src]="images[titleImageIndex]" alt="Titelbild">
            </div>
          </div>
          <!-- Title -->
          <span class="font-title">{{ title }}</span>
          <span
            class="font-description">{{ description }}{{ cipherType !== CipherType.NONE ? '. Zuschriften über kleinanzeigen.bo.de mit der Nr. XXXXXX oder an Mittelbadische Presse, Marlener Str. 9, 77656 Offenburg.' : '' }}</span>
        </div>
        <!-- Divider -->
        <div class="h-2 w-full bg-[#FAFAFA] my-5 md:hidden"></div>
      </div>
      <!-- Close in DesktopView -->
      <div class="hidden md:flex md:justify-center md:text-[#224261] md:text-base">
        <fa-icon class="cursor-pointer h-5" (click)="showAdPreview = false" icon="xmark"></fa-icon>
      </div>
    </div>
    <!--BillingAddress-->
    <div *ngIf="adType !== AdType.GOOD_DEED" class="flex flex-col">
      <!-- Bankdaten & Bankinfo-->
      <div class="flex flex-col">
            <span
              class="text-base text-center text-[#222222] font-medium md:text-start md:text-xl md:font-semibold">Bankdaten</span>
        <span class="text-sm text-[#F0811A] font-semibold my-3">
              ** Leider können wir aktuell nur Lastschriftmandate annehmen. Bitte füllen Sie die folgenden Felder aus:
            </span>
      </div>
      <div class="flex flex-col">
        <div class="flex flex-col gap-4 md:grid md:grid-cols-2">
          <kaz-input [(value)]="billingAddress.bank" [maxLength]="40" placeholder="Bank *"></kaz-input>
          <kaz-input [(value)]="billingAddress.iban" [maxLength]="40" placeholder="IBAN *"></kaz-input>
        </div>
        <span class="text-sm text-[#F0811A] font-semibold mt-1.5">
              Hiermit bestätige ich, dass der Betrag von meinem Konto abgebucht werden darf.
            </span>
      </div>
    </div>
    <!-- Divider -->
    <div class="h-2 w-full bg-[#FAFAFA] my-1 md:hidden"></div>
    <!-- PaymentBtn -->
    <div class="flex flex-col pl-[-4rem] md:w-full items-center">
      <kaz-button buttonType="bordered" (onClick)="createAd()" [disabled]="billingAddressInvalid()"
                  [isLoading]="isLoading" width="343px"
                  [text]="adType === AdType.GOOD_DEED ? 'Anzeige aufgeben' : 'Anzeige kostenpflichtig aufgeben'"></kaz-button>
    </div>

  </div>


</div>

<kaz-card-category-hint *ngIf="showCategoryHint" (closeHintOnEmit)="showCategoryHint = false"
                        [category]="categoryHint"></kaz-card-category-hint>

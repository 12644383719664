import {Component, Input} from '@angular/core';
import {Ad, PriceType} from "../../interfaces/ad";
import {getDummyPicture} from "../../utils/dummyImages";

@Component({
    selector: 'kaz-card-default',
    templateUrl: './kaz-card-default.component.html',
    styleUrls: ['./kaz-card-default.component.scss'],
    standalone: false
})
export class KazCardDefaultComponent {
  @Input() ad: Ad | null = null;
  protected readonly getDummyPicture = getDummyPicture;
    protected readonly PriceType = PriceType;
}

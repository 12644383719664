import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";

@Component({
    selector: 'kaz-favorites',
    templateUrl: './kaz-favorites.component.html',
    styleUrls: ['./kaz-favorites.component.scss'],
    standalone: false
})
export class KazFavoritesComponent implements OnInit{

  constructor(private _api: ApiService) {
  }

  ngOnInit() {
  }
}

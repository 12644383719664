<div *ngIf="showReportAd && ad" class="fixed top-0 left-0 w-full h-full bg-black/70">
  <div
    class="fixed top-0 left-0 flex flex-col p-4 w-full h-full bg-white md:bg-[#FAFAFA] md:w-[1080px] md:h-[743px] md:px-16 md:py-8 md:rounded-[20px] md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2">
    <!-- Header -->
    <div class="relative flex flex-row h-12 justify-center items-center">
      <span (click)="closeReportAd()" class="absolute left-4 text-xs text-[#224261] md:cursor-pointer">Abbrechen</span>
      <span class="grow text-base text-center">Kleinanzeige melden</span>
      <span class="absolute right-4 text-xs"
            [class]="reportMessage !== '' && reportReason !== '' ? 'md:cursor-pointer text-[#F0811A]' : 'pointer-events-none text-[#A7A7A7]'"
            (click)="createReport()">Senden</span>
    </div>
    <!-- Divider -->
    <div class="h-[1px] bg-[#EFEFEF]"></div>
    <!-- Provider -->
    <div *ngIf="ad" class="flex flex-row gap-4 my-4 items-center">
      <!-- Bild -->
      <div class="relative flex overflow-hidden w-10 h-10 bg-[#F5F5F5] justify-center items-center rounded-full"
           routerLink="/entry/{{ad.id}}">
        <img class="w-auto h-full object-cover" [src]="ad.images.length > 0 ? (ad.images[ad.titleImageIndex] | imageSource) : getDummyPicture(ad)" alt="Bild">
      </div>
      <div *ngIf="ad" class="flex flex-col font-semibold">
        <span class="text-sm text-[#222222]">{{ad.title}}</span>
        <div class="text-xs text-[#999999]">
          <fa-icon icon="hashtag"></fa-icon>
          <span>{{ad.cipher.number}}</span>
        </div>
        <span *ngIf="ad.category.subName !== 'Verschenken' && +ad.price > 0"
              class="text-xs text-[#56BA90]">€ {{ad.price | currency:'EUR':'':'1.0-0':'de-DE'}} {{ad.priceType === PriceType.VHB ? 'VHB' : '' }}</span>
      </div>
    </div>
    <!-- DropDown -->
    <select [(ngModel)]="reportReason"
      class="leading-4 h-12 px-4 mb-5 text-base font-medium border border-[#999999] bg-[#FAFAFA] rounded-[6px] md:cursor-pointer"
      [class]="reportReason === '' ? 'text-[#9ca3af]' : 'text-[#222222]'">
      <option value="" disabled selected>Wählen Sie einen Grund</option>
      <option *ngFor="let reason of reportList" [value]="reason">{{reason}}</option>
    </select>
    <div>

    </div>
    <!-- Nachricht -->
    <textarea [(ngModel)]="reportMessage"
              class="p-4 mt-2 h-[205px] leading-6 resize-none text-base text-[#161D26] font-medium border border-[#999999] rounded-[6px] bg-[#F7F7F7]"
              placeholder="Teilen Sie uns Ihre Bedenken mit"></textarea>
  </div>
</div>

import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DefaultFrameComponent } from './frames/default-frame/default-frame.component';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import { NoFrameComponent } from './frames/no-frame/no-frame.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { KazInputComponent } from './components/kaz-input/kaz-input.component';
import {FormsModule} from "@angular/forms";
import { KazButtonComponent } from './components/kaz-button/kaz-button.component';
import { KazDividerComponent } from './components/kaz-divider/kaz-divider.component';
import { ResetComponent } from './pages/auth/reset/reset.component';
import { SignupComponent } from './pages/auth/signup/signup.component';
import {ApiService} from "./services/api.service";
import {AuthenticationService} from "./services/auth.service";
import {CookieService} from "ngx-cookie-service";
import {HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { MainComponent } from './pages/main/main.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { KazCookieBannerComponent } from './components/kaz-cookie-banner/kaz-cookie-banner.component';
import { KazMenuComponent } from './components/kaz-menu/kaz-menu.component';
import { KazAdsComponent } from './components/kaz-ads/kaz-ads.component';
import { KazMessagesComponent } from './components/kaz-messages/kaz-messages.component';
import { KazFavoritesComponent } from './components/kaz-favorites/kaz-favorites.component';
import { InfoComponent } from './pages/info/info.component';
import { CreateAdComponent } from './pages/ad/create-ad/create-ad.component';
import {DisplayDatePipe} from "./pipes/displaydate.pipe";
import { KazAdInfoComponent } from './components/kaz-ad-info/kaz-ad-info.component';
import { KazSearchComponent } from './components/kaz-search/kaz-search.component';
import { KazSlideMenuComponent } from './components/kaz-slide-menu/kaz-slide-menu.component';
import { KazCardLastSeenComponent } from './components/kaz-card-last-seen/kaz-card-last-seen.component';
import { KazCardRecommendedComponent } from './components/kaz-card-recommended/kaz-card-recommended.component';
import { KazCardActiveAdsComponent } from './components/kaz-card-active-ads/kaz-card-active-ads.component';
import { DetailAdComponent } from './pages/ad/detail-ad/detail-ad.component';
import { KazFastMessageComponent } from './components/kaz-fast-message/kaz-fast-message.component';
import { ImageSourcePipe } from './pipes/image-source.pipe';
import { KazCardDefaultComponent } from './components/kaz-card-default/kaz-card-default.component';
import { KazDefaultInfoComponent } from './components/kaz-default-info/kaz-default-info.component';
import { EditAdComponent } from './pages/ad/edit-ad/edit-ad.component';
import { KazCardChatComponent } from './components/kaz-card-chat/kaz-card-chat.component';
import { ImprintComponent } from './pages/footer/imprint/imprint.component';
import { PrivacyComponent } from './pages/footer/privacy/privacy.component';
import { ConditionsComponent } from './pages/footer/conditions/conditions.component';
import { FundamentalsComponent } from './pages/footer/fundamentals/fundamentals.component';
import { AgbComponent } from './pages/footer/agb/agb.component';
import { FaqComponent } from './pages/faq/faq.component';
import { KazCollapseComponent } from './components/kaz-collapse/kaz-collapse.component';
import { SearchAdComponent } from './pages/ad/search-ad/search-ad.component';
import { CardReportAdComponent } from './components/card-report-ad/card-report-ad.component';
import { CardCategoryHintComponent } from './components/card-category-hint/card-category-hint.component';
import { VerifyComponent } from './pages/auth/verify/verify.component';
import { SuccessAdComponent } from './pages/ad/create-ad/success-ad/success-ad.component';
import {CancelAdComponent} from "./pages/ad/create-ad/cancel-ad/cancel-ad.component";
import { ValidateComponent } from './pages/auth/validate/validate.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { KazFastSearchComponent } from './components/kaz-fast-search/kaz-fast-search.component';
import { KazArchiveComponent } from './components/kaz-archive/kaz-archive.component';
import { KazCardArchiveAdsComponent } from './components/kaz-card-archive-ads/kaz-card-archive-ads.component';
import {NgxImageCompressService} from "ngx-image-compress";
import { SmartEntryComponent } from './components/smart-entry/smart-entry.component';
import { ToastComponent } from './components/toast/toast.component';

@NgModule({
  declarations: [
    AppComponent,
    DefaultFrameComponent,
    NoFrameComponent,
    LoginComponent,
    KazInputComponent,
    KazButtonComponent,
    KazDividerComponent,
    ResetComponent,
    SignupComponent,
    MainComponent,
    ProfileComponent,
    KazCookieBannerComponent,
    KazMenuComponent,
    KazAdsComponent,
    KazMessagesComponent,
    KazFavoritesComponent,
    InfoComponent,
    CreateAdComponent,
    DisplayDatePipe,
    KazAdInfoComponent,
    KazSearchComponent,
    KazSlideMenuComponent,
    KazCardLastSeenComponent,
    KazCardRecommendedComponent,
    KazCardActiveAdsComponent,
    DetailAdComponent,
    KazFastMessageComponent,
    ImageSourcePipe,
    KazCardDefaultComponent,
    KazDefaultInfoComponent,
    EditAdComponent,
    KazCardChatComponent,
    ImprintComponent,
    PrivacyComponent,
    ConditionsComponent,
    FundamentalsComponent,
    AgbComponent,
    FaqComponent,
    KazCollapseComponent,
    SearchAdComponent,
    CardReportAdComponent,
    CardCategoryHintComponent,
    VerifyComponent,
    SuccessAdComponent,
    CancelAdComponent,
    ValidateComponent,
    ResetPasswordComponent,
    KazFastSearchComponent,
    KazArchiveComponent,
    KazCardArchiveAdsComponent,
    SmartEntryComponent,
    ToastComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgOptimizedImage,
    FormsModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
  ],
  providers: [
    ApiService,
    AuthenticationService,
    CookieService,
    NgxImageCompressService,
    {provide: LOCALE_ID, useValue:'de-DE'},
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

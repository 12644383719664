import {Component, Input, OnInit} from '@angular/core';
import {Ad, PriceType} from "../../interfaces/ad";
import {getDummyPicture} from "../../utils/dummyImages";

@Component({
    selector: 'kaz-card-last-seen',
    templateUrl: './kaz-card-last-seen.component.html',
    styleUrls: ['./kaz-card-last-seen.component.scss'],
    standalone: false
})
export class KazCardLastSeenComponent implements OnInit{
  @Input() ad: Ad | null = null;

  constructor() {
  }

  ngOnInit() {

  }

  protected readonly getDummyPicture = getDummyPicture;
    protected readonly PriceType = PriceType;
}

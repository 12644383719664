import {AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Chat} from "../../interfaces/chat";
import {Ad} from "../../interfaces/ad";
import {ApiService} from "../../services/api.service";
import {Message} from "../../interfaces/message";
import {AuthenticationService} from "../../services/auth.service";
import {getDummyPicture} from "../../utils/dummyImages";

@Component({
    selector: 'kaz-card-chat',
    templateUrl: './kaz-card-chat.component.html',
    styleUrls: ['./kaz-card-chat.component.scss'],
    standalone: false
})
export class KazCardChatComponent implements OnInit, AfterViewChecked{
  @ViewChild('chatHistory') chatHistory!: ElementRef;

  @Input() chat: Chat | null = null;
  @Input()  active: Chat | null = null;

  ad: Ad | null = null;
  message: Message | null = null;
  messages: Message[] | null = null;
  showChat: boolean = false;

  msg: string = '';

  constructor(private _api: ApiService,
              private _auth: AuthenticationService) {
  }

  async ngOnInit() {
    if (this.chat !== null) {
      await this.getAd(this.chat.adID);
      await this.getLastChatMessage();
      await this.getMessagesOfChat();
    }
  }

  ngAfterViewChecked() {
    if (this.chatHistory) {
      this.chatHistory.nativeElement.scrollTop = this.chatHistory.nativeElement.scrollHeight;
    }
  }

  async getAd(adID: string) {
    try {
      this.ad = await this._api.getAd(adID);
    } catch (err) {
      console.log(err);
    }
  }

  async getLastChatMessage(){
    try {
      if (this.chat !== null){
        const lastChatMessage = this.chat.chatMessageIDs.pop();
        if (lastChatMessage !== undefined){
          this.message = await this._api.getMessage(lastChatMessage);
          console.log(this.message)
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  openChat() {
    this.showChat = true;
  }

  private async getMessagesOfChat() {
    try {
      if (this.chat !== null){
        this.messages = await this._api.getMessagesOfChat(this.chat.id)
      }
    } catch (err) {
      console.log(err);
    }
  }

  async sendMessage() {
    try {
      if (this.chat !== null && this.msg !== ''){
        this.chat = await this._api.updateChat(this.chat.id, this.msg);
        this.msg = '';
        await this.getMessagesOfChat();
      }
    } catch (err) {
      console.log(err);
    }
  }

  isOwnMsg(id: string) {
    const userId = this._auth.getSelfID();
    return userId !== null && userId === id;
  }

  protected readonly getDummyPicture = getDummyPicture;
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {Ad, PriceType} from "../../interfaces/ad";
import {getDummyPicture} from "../../utils/dummyImages";

@Component({
    selector: 'kaz-card-active-ads',
    templateUrl: './kaz-card-active-ads.component.html',
    styleUrls: ['./kaz-card-active-ads.component.scss'],
    standalone: false
})
export class KazCardActiveAdsComponent implements OnInit {
  @Output() wasDeleted = new EventEmitter<void>();
  @Input() ad: Ad | null = null;
  updatedAd: Partial<Ad> | null = null;
  showDelete: boolean = false;

  constructor(private _api: ApiService) {
  }

  async ngOnInit() {
  }

  async toggleReserved() {
    try {
      if (this.ad !== null) {
        this.updatedAd = {
          id: this.ad.id,
          reserved: !this.ad.reserved,
        };
        this.ad = await this._api.updateAd(this.updatedAd);
      }
    } catch (err) {

    }
  }

  toggleDelete() {
    this.showDelete = !this.showDelete;
  }

  async deleteAd() {
    try {
      if (this.ad !== null) {
        await this._api.deleteAd(this.ad.id);
      }
      this.wasDeleted.emit();
    } catch (err) {

    }
  }

  protected readonly getDummyPicture = getDummyPicture;

  editDisabled(ad: Ad) {
    const today = new Date();
    const firstPubDate = new Date(ad.publicationDate[0]);

    return (firstPubDate < today)
  }

    protected readonly PriceType = PriceType;
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconProp} from "@fortawesome/fontawesome-svg-core";

@Component({
    selector: 'kaz-button',
    templateUrl: './kaz-button.component.html',
    styleUrls: ['./kaz-button.component.scss'],
    standalone: false
})
export class KazButtonComponent {
  @Input('text') text: string = "";
  @Input('title') title: string = "";
  @Input('width') width: string = "100%";
  @Input('height') height: string = "3rem";
  @Input('icon') icon: IconProp | null = null;
  @Input('disabled') disabled: boolean = false;
  @Input('imagePath') image: string = "";
  @Input('isLoading') isLoading: boolean = false;
  @Input() fileButton: boolean = false;


  @Input('buttonType') buttonType: 'primary'|'bordered'|'secondary'|'cookies' = 'primary';

  @Output('onClick') onClick: EventEmitter<void> = new EventEmitter<void>();
}

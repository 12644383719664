import {Address} from "./address";

export interface Cipher {
  number: number;
  type: CipherType;
  firstname: string;
  lastname: string;
  address: Address;
}

export enum CipherType{
  NONE = 'NONE',
  CHAT = 'CHAT',
  LETTER = 'LETTER',
  OFFICE = 'OFFICE',
}

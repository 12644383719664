<kaz-default-frame>
  <div class="flex flex-col gap-8 text-sm text-[#222222] pb-6">
    <span class="font-semibold text-xl">Datenschutz - Datenschutzerklärung</span>
    <div class="flex flex-col gap-4">
      <span>Wir freuen uns über Ihren Besuch auf unserer Webseite. Der Schutz Ihrer persönlichen Daten ist uns ein wichtiges Anliegen.</span>
      <span>Generell ist eine Nutzung unserer Webseite ohne Angabe von personenbezogenen Daten möglich. Für die Nutzung besonderer Dienste oder Services kann eine Abfrage Ihrer Daten jedoch erforderlich werden. Die Verarbeitung dieser Daten erfolgt im Einklang mit den Richtlinien der Datenschutzgrundverordnung. Besteht für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir die Einwilligung der betroffenen Person ein.</span>
      <span>Mit dieser Datenschutzerklärung informieren wir die Öffentlichkeit über Art, Umfang und Zweck der von uns erhobenen und verarbeiteten Daten.</span>
    </div>

    <div class="flex flex-col gap-4">
      <span class="font-semibold text-base">1. Begriffsbestimmungen</span>
      <span>Unsere Datenschutzerklärung beruht auf den Begrifflichkeiten, die durch die Europäische Union beim Erlass der Datenschutzgrundverordnung (DSGVO) verwendet wurden. Sie soll sowohl für die Öffentlichkeit als auch für unsere Kunden und Geschäftspartner einfach lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.</span>
      <span>Wir verwenden in dieser Datenschutzerklärung und auf unserer Webseite unter anderem die folgenden Begriffe:</span>

      <span>1.1 Personenbezogene Daten</span>
      <span>Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.</span>

      <span>1.2 Betroffene Person</span>
      <span>Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet werden.</span>

      <span>1.3 Verarbeitung</span>
      <span>Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</span>

      <span>1.4 Einschränkung der Verarbeitung</span>
      <span>Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.</span>

      <span>1.5 Profiling</span>
      <span>Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.</span>

      <span>1.6 Pseudonymisierung</span>
      <span>Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.</span>

      <span>1.7 Verantwortlicher oder für die Verarbeitung Verantwortlicher</span>
      <span>Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.</span>

      <span>1.8 Auftragsverarbeiter</span>
      <span>Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.</span>

      <span>1.9 Empfänger</span>
      <span>Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.</span>

      <span>1.10 Dritter</span>
      <span>Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.</span>

      <span>1.11 Einwilligung</span>
      <span>Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.</span>

      <span class="font-semibold text-base">2. Erfassung allgemeiner Informationen</span>

      <span>Wenn Sie unsere Webseite öffnen, werden automatisch allgemeine Informationen (Server-Logfiles) erfasst. Erfasst werden können:</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>
          <p style="margin-bottom: 0cm">die verwendeten Browsertypen und Versionen</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">das vom zugreifenden System verwendete Betriebssystem</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">die Webseite, von welcher ein zugreifendes System auf unsere Webseite gelangt
            (so genannte Referrer)</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">die Unter-Webseiten, welche über ein zugreifendes System auf unserer Webseite
            angesteuert werden</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">das Datum und die Uhrzeit eines Zugriffs auf die Webseite</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">eine Web-Protokoll-Adresse (IP-Adresse)</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">der Internet-Service-Provider des zugreifenden Systems</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von
            Angriffen auf unsere informationstechnologischen Systeme dienen</p>
        </li>

      </ul>

      <span>Diese Informationen lassen keine Rückschlüsse auf Ihre Person zu. Sie sind aber aus verschiedenen Gründen notwendig, um:</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>
          <p style="margin-bottom: 0cm">die Inhalte unserer Webseite korrekt auszuliefern</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">die Inhalte unserer Webseite sowie die Werbung für diese zu optimieren</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">die dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme
            und der Technik unserer Webseite zu gewährleisten</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung
            notwendigen Informationen bereitzustellen</p>
        </li>

      </ul>

      <span class="font-semibold text-base">3. Cookies</span>

      <span>Wir möchten unsere Webseite für Sie benutzerfreundlich gestalten und genau auf Ihre Bedürfnisse abstimmen. Deshalb verwenden wir wie viele andere Webseiten auch sogenannte „Cookies“.</span>
      <span>Cookies sind kleine Textdateien, die über einen Browser auf einem Computersystem abgelegt und gespeichert werden. Hierdurch erhalten wir Daten über Ihren Computer und Ihre Internetverbindung, wie z. B. Ihre IP-Adresse. Der Einsatz von Cookies ist für Sie unbedenklich. Sie können nicht verwendet werden, um Programme zu starten oder Viren auf einen Computer zu übertragen. Außerdem geben wir die erfassten Daten in keinem Fall an Dritte weiter oder stellen ohne Ihre Einwilligung eine Verknüpfung mit personenbezogenen Daten her. Der Nutzen bei der Verwendung von Cookies besteht darin, dass wir Ihnen die Navigation erleichtern können und die korrekte Anzeige unserer Webseite ermöglicht wird.</span>
      <span>Natürlich können Sie unsere Webseite grundsätzlich auch ohne Cookies betrachten. Internetprogramme sind regelmäßig so eingestellt, dass sie Cookies akzeptieren, sie können dies aber jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte verwenden Sie die Hilfefunktionen Ihres Browsers, um zu erfahren, wie Sie diese Einstellungen ändern können. Bitte beachten Sie, dass einzelne Funktionen unserer Webseite möglicherweise nicht funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.</span>

      <span class="font-semibold text-base">4. Rechte der betroffenen Person</span>

      <span>4.1 Recht auf Bestätigung</span>
      <span>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, jederzeit von dem für die Verarbeitung Verantwortlichen unentgeltliche Auskunft über die zu seiner Person gespeicherten personenbezogenen Daten sowie eine Kopie dieser Auskunft zu erhalten. Ferner hat der Europäische Richtlinien- und Verordnungsgeber der betroffenen Person Auskunft über folgende Informationen zugestanden:</span>

      <span>4.2 Recht auf Auskunft (Art. 15 DSGVO)</span>
      <span>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, jederzeit von dem für die Verarbeitung Verantwortlichen unentgeltliche Auskunft über die zu seiner Person gespeicherten personenbezogenen Daten sowie eine Kopie dieser Auskunft zu erhalten. Ferner hat der Europäische Richtlinien- und Verordnungsgeber der betroffenen Person Auskunft über folgende Informationen zugestanden:</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>
          <p style="margin-bottom: 0cm">die Verarbeitungszwecke</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">die Kategorien personenbezogener Daten, die verarbeitet werden</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">die Empfänger oder Kategorien von Empfängern, gegenüber denen die
            personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in
            Drittländern oder bei internationalen Organisationen</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">falls möglich die geplante Dauer, für die die personenbezogenen Daten
            gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden
            personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines
            Widerspruchsrechts gegen diese Verarbeitung</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben
            werden: Alle verfügbaren Informationen über die Herkunft der Daten</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling
            gemäß Artikel 22 Abs.1 und 4 EU-DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über
            die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung
            für die betroffene Person</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob
            personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt wurden. Sofern
            dies der Fall ist, so steht der betroffenen Person im Übrigen das Recht zu, Auskunft über die geeigneten
            Garantien im Zusammenhang mit der Übermittlung zu erhalten.</p>
        </li>

      </ul>
      <span>Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an den für die Verarbeitung Verantwortlichen wenden.</span>

      <span>4.3 Recht auf Berichtigung (Art. 16 DSGVO)</span>
      <span>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht der betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten, auch mittels einer ergänzenden Erklärung, zu verlangen.</span>
      <span>Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an den für die Verarbeitung Verantwortlichen wenden.</span>

      <span>4.4 Recht auf Löschung (Recht auf Vergessenwerden, Art. 17 DSGVO)</span>
      <span>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem Verantwortlichen zu verlangen, dass die sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und soweit die Verarbeitung nicht erforderlich ist:</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>
          <p style="margin-bottom: 0cm">Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige
            Weise verarbeitet, für welche sie nicht mehr notwendig sind.</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung
            gemäß Art. 6 Abs. 1 Buchstabe a EU-DSGVO oder Art. 9 Abs. 2 Buchstabe a EU-DSGVO stützte, und es fehlt an
            einer anderweitigen Rechtsgrundlage für die Verarbeitung.</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">Die betroffene Person legt gemäß Art. 21 Abs. 1 EU-DSGVO Widerspruch gegen die
            Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die
            betroffene Person legt gemäß Art. 21 Abs. 2 EU-DSGVO Widerspruch gegen die Verarbeitung ein.</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen
            Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche
            unterliegt.</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
            Informationsgesellschaft gemäß Art. 8 Abs. 1 EU-DSGVO erhoben.</p>
        </li>

      </ul>
      <span>Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die Löschung von personenbezogenen Daten, die bei uns gespeichert sind, veranlassen möchte, kann sie sich hierzu jederzeit an den für die Verarbeitung Verantwortlichen wenden. Dem Löschverlangen der betroffenen Person wird dann unverzüglich nachgekommen.</span>
      <span>Wurden die personenbezogenen Daten öffentlich gemacht und ist unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 EU-DSGVO zur Löschung der personenbezogenen Daten verpflichtet, so treffen wir unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um andere für die Datenverarbeitung Verantwortliche, welche die veröffentlichten personenbezogenen Daten verarbeiten, darüber in Kenntnis zu setzen, dass die betroffene Person von diesen anderen für die Datenverarbeitung Verantwortlichen die Löschung sämtlicher Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat, soweit die Verarbeitung nicht erforderlich ist. Der für die Verarbeitung Verantwortliche dann wird im Einzelfall das Notwendige veranlassen.</span>

      <span>4.5 Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</span>
      <span>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>
          <p style="margin-bottom: 0cm">Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person
            bestritten, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der
            personenbezogenen Daten zu überprüfen.</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der
            personenbezogenen Daten ab und verlangt stattdessen die Einschränkung der Nutzung der personenbezogenen
            Daten.</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der
            Verarbeitung nicht länger, die betroffene Person benötigt sie jedoch zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen.</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1
            EU-DSGVO eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des Verantwortlichen gegenüber
            denen der betroffenen Person überwiegen.</p>
        </li>

      </ul>
      <span>Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene Person die Einschränkung von personenbezogenen Daten verlangen möchte, kann sie sich hierzu jederzeit an den für die Verarbeitung Verantwortlichen wenden. Die Einschränkung der Verarbeitung wird dann unverzüglich veranlasst.</span>

      <span>4.6 Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</span>
      <span>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die sie betreffenden personenbezogenen Daten, welche durch die betroffene Person einem Verantwortlichen bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie hat außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a EU-DSGVO oder Art. 9 Abs. 2 Buchstabe a EU-DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b EU-DSGVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde.</span>
      <span>Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 EU-DSGVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.</span>
      <span>Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die betroffene Person jederzeit an den für die Verarbeitung Verantwortlichen wenden.</span>

      <span>4.7 Recht auf Widerspruch (Art. 21 DSGVO)</span>
      <span>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f EU-DSGVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</span>
      <span>Wir verarbeiten die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</span>
      <span>Verarbeiten wir die personenbezogenen Daten, um Direktwerbung zu betreiben, so hat die betroffene Person das Recht, jederzeit Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Widerspricht die betroffene Person der Verarbeitung für Zwecke der Direktwerbung, so werden wir die personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.</span>
      <span>Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, gegen die sie betreffende Verarbeitung personenbezogener Daten, die bei uns zu wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 EU-DSGVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich.</span>
      <span>Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt an den für die Verarbeitung Verantwortlichen wenden. Der betroffenen Person steht es ferner frei, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.</span>

      <span>4.8 Automatisierte Entscheidungen im Einzelfall einschließlich Profiling</span>
      <span>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling –beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche Wirkung entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt, sofern die Entscheidung:</span>
      <ul role="list" class="marker:text-[#F0811A] list-disc pl-5">
        <li>
          <p style="margin-bottom: 0cm">nicht für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich ist oder</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen Person enthalten oder</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">mit ausdrücklicher Einwilligung der betroffenen Person erfolgt.</p>
        </li>
        <li>
          <p style="margin-bottom: 0cm">Ist die Entscheidung für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich oder erfolgt sie mit ausdrücklicher Einwilligung der betroffenen Person, treffen wir angemessene Maßnahmen, um die Rechte und Freiheiten sowie die berechtigten Interessen der betroffenen Person zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.</p>
        </li>

      </ul>
      <span>Möchte die betroffene Person Rechte mit Bezug auf automatisierte Entscheidungen geltend machen, kann sie sich hierzu jederzeit an den für die Verarbeitung Verantwortlichen wenden.</span>

      <span>4.9 Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</span>
      <span>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.</span>
      <span>Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung geltend machen, kann sie sich hierzu jederzeit an den für die Verarbeitung Verantwortlichen wenden.</span>

      <span class="font-semibold text-base">5. Registrierung auf unserer Webseite</span>

      <span>Die Nutzung mancher Inhalte und Leistungen erfordert eine Registrierung auf unserer Webseite. Dabei werden personenbezogene Daten erhoben, z. B. Name, Anschrift und Kontaktdaten wie Telefonnummer und E-Mail-Adresse. Sie haben die Möglichkeit, die angegebenen Daten jederzeit zu ändern oder zu löschen. Selbstverständlich erteilen wir Ihnen darüber hinaus jederzeit Auskunft über die von uns gespeicherten personenbezogenen Daten. Gerne berichtigen bzw. löschen wir diese auch auf Ihren Wunsch, soweit keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Zur Kontaktaufnahme in diesem Zusammenhang nutzen Sie bitte die am Ende dieser Datenschutzerklärung angegebenen Kontaktdaten.</span>

      <span class="font-semibold text-base">6. Erbringung kostenpflichtiger Leistungen</span>

      <span>Sollten Sie über unsere Webseite eine kostenpflichtige Leistung bestellen, werden von uns zusätzliche Daten erfragt, wie z.B. Zahlungsangaben.</span>
      <span>Bei Abschluss einer kostenpflichtigen Leistung ziehen wir zum Zwecke der Bonitätsprüfung die Creditreform Offenburg Zimmermann KG, Lange Str. 18, 77652 Offenburg, hinzu. Diese verwendet in ihrer Datenbank oder unter Zuhilfenahme Dritter gespeicherte Adress- und Bonitätsdaten einschließlich solcher, die auf der Basis mathematisch-statistischer Verfahren ermittelt werden. Ihre persönlichen Daten (Name, Adresse, Geburtsdatum) werden im Falle einer Prüfung vor Vertragsschluss dorthin weitergeleitet.</span>

      <span class="font-semibold text-base">7. Kommentarfunktion</span>

      <span>Wenn Sie einen Kommentar auf unserer Webseite hinterlassen, werden neben dem Kommentar selbst auch der Zeitpunkt der Erstellung und der zuvor von Ihnen gewählte Nutzername gespeichert. Dies dient unserer Sicherheit, da wir für widerrechtliche Inhalte auf unserer Webseite belangt werden können, auch wenn diese durch Benutzer erstellt wurden.</span>

      <span class="font-semibold text-base">8. Newsletter</span>

      <span>Bei der Anmeldung für einen Newsletter werden die von Ihnen angegebenen Daten ausschließlich für diesen Zweck verwendet. Newsletter-Abonnenten können zudem auch über Umstände per E-Mail informiert werden, die für den Dienst oder die Registrierung relevant sind (beispielsweise Änderungen des Newsletterangebots oder technische Gegebenheiten).</span>
      <span> Für eine wirksame Registrierung überprüfen wir, ob die angegebene E-Mail-Adresse wirklich zu Ihnen gehört. Dazu setzen wir das „Double-opt-in“-Verfahren ein. Hierzu protokollieren wir die Bestellung des Newsletters, den Versand einer Bestätigungsmail und den Eingang der damit angeforderten Antwort. Weitere Daten werden nicht erhoben. Die protokollierten Daten werden ausschließlich für den Newsletterversand verwendet und nicht an Dritte weitergegeben.</span>
      <span>Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletterversand können Sie jederzeit widerrufen. In jedem Newsletter findet sich dazu ein entsprechender Link. Außerdem können Sie sich jederzeit auch direkt auf dieser Webseite abmelden oder uns Ihren Wunsch über die am Ende dieser Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.</span>

      <span class="font-semibold text-base">9. Kontaktformular</span>

      <span>Treten Sie per E-Mail oder Kontaktformular mit uns in Kontakt, werden die von Ihnen gemachten Angaben zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.</span>

      <span class="font-semibold text-base">10. Löschung bzw. Sperrung der Daten</span>

      <span>Wir sammeln so wenige personenbezogene Daten wie möglich und nur so lange, wie zur Erreichung der hier genannten Zwecke erforderlich oder vom Gesetzgeber in Speicherfristen vorgesehen. Nach Entfall des jeweiligen Zwecks bzw. Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.</span>

      <span class="font-semibold text-base">11. Verwendung von Google Analytics</span>

      <span>Diese Webseite benutzt Google Analytics, einen Webanalysedienst der Google Inc. (folgend: Google). Google Analytics ermöglicht eine Analyse der Benutzung der Webseite über „Cookies“. Die durch das Cookie erzeugten Nutzungsdaten, wie z. B. Ihre IP-Adresse, werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Wir haben eine IP-Anonymisierung auf unseren Webseiten aktiviert. Deshalb wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der EU oder des Europäischen Wirtschaftsraums gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Google benutzt diese Informationen, um Ihre Nutzung der Webseite auszuwerten, um Webseitenaktivitäten zusammenzustellen und um weitere mit der Webseitennutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Eine Zusammenführung Ihrer IP-Adresse mit anderen von Google erhobenen Daten findet nicht statt.</span>
      <span>Sie können die Speicherung der Cookies in den Einstellungen Ihres Browsers verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Webseite vollumfänglich nutzen können. Außerdem können Sie die Datenerfassung über das Cookie sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a class="underline text-[#F0811A]" href="http://tools.google.com/dlpage/gaoptout?hl=de">Browser-Add-on zur Deaktivierung von Google Analytics</a>.</span>
      <span>Zusätzlich oder als Alternative zum Browser-Add-On können Sie die Erhebung durch Google Analytics auf unseren Seiten unterbinden, indem Sie <a class="underline text-[#F0811A]" href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/ergebnis/">diesen Link anklicken</a>. Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät installiert. Solange dieser installiert ist, wird die Erfassung durch Google Analytics für diese Webseite und für diesen Browser zukünftig verhindert, solange der Cookie in Ihrem Browser installiert bleibt.</span>

      <span class="font-semibold text-base">12. Nutzung des Skalierbaren Zentralen Messverfahrens</span>

      <span>Mit dem sogenannten „Skalierbaren Zentralen Messverfahren“ <a class="underline text-[#F0811A]" href="http://www.infonline.de/">(SZM) der INFOnline GmbH</a> messen wir die Reichweite unserer Angebote, also die Anzahl der Seitenzugriffe. Dabei werden anonyme Messwerte erhoben. Damit ein Nutzer bei der SZM-Reichweitenmessung wiedererkannt wird, verwenden wir entweder ein Cookie mit der Kennung „ioam.de“, „ivwbox.de“, ein LocalStorage Objekt oder eine Signatur, die aus verschiedenen automatisch übertragenen Informationen Ihres Browsers erstellt wird. IP-Adressen werden auch hier nur in anonymisierter Form verarbeitet.</span>
      <span>Die Reichweitenmessung wurde unter der Beachtung des Datenschutzes entwickelt. Ihr Ziel ist es, die Nutzungsintensität und die Anzahl der Nutzer einer Webseite statistisch zu bestimmen. Zu keinem Zeitpunkt werden einzelne Nutzer identifiziert. Ihre Identität bleibt immer geschützt. Sie erhalten über das System keine Werbung.</span>
      <span>Für Mitglieder der Informationsgemeinschaft zur Feststellung der Verbreitung von Werbeträgern e.V. (IVW – <a class="underline text-[#F0811A]" href="http://www.ivw.eu/">www.ivw.eu</a>) oder Teilnehmer der Studie „internet facts“ der Arbeitsgemeinschaft Online-Forschung e.V. (AGOF – <a class="underline text-[#F0811A]" href="http://www.agof.de/">www.agof.de</a>) werden die Nutzungsstatistiken monatlich von der AGOF und der Arbeitsgemeinschaft Media-Analyse e.V. (ag.ma – <a class="underline text-[#F0811A]" href="http://www.agma-mmc.de/">www.agma-mmc.de</a>), sowie der IVW veröffentlicht und können unter <a class="underline text-[#F0811A]" href="http://www.agof.de/">www.agof.de</a>, <a class="underline text-[#F0811A]" href="http://www.agma-mmc.de/">www.agma-mmc.de</a> und <a class="underline text-[#F0811A]" href="http://www.ivw.eu/">www.ivw.eu</a> eingesehen werden. Neben der Veröffentlichung von Messdaten überprüft die IVW das SZM-Verfahren regelmäßig im Hinblick auf eine regel- und datenschutzkonforme Nutzung.</span>
      <span>Weitere Informationen zum SZM-Verfahren finden Sie auf der Webseite der INFOnline GmbH (<a class="underline text-[#F0811A]" href="http://www.infonline.de/">www.infonline.de</a>), die das SZM-Verfahren betreibt, der DatenschutzWebseite der AGOF (<a class="underline text-[#F0811A]" href="http://www.agof.de/datenschutz">www.agof.de/datenschutz</a>) und der DatenschutzWebseite der IVW (<a class="underline text-[#F0811A]" href="http://www.ivw.eu/">www.ivw.eu</a>).</span>
      <span>Sie können der Datenverarbeitung durch das SZM unter folgenden Links widersprechen: <a class="underline text-[#F0811A]" href="http://optout.ioam.de">optout.ioam.de</a> und <a class="underline text-[#F0811A]" href="http://optout.ivwbox.de">optout.ivwbox.de</a>.</span>

      <span class="font-semibold text-base">13. Nutzung des Skalierbaren Zentralen Messverfahrens durch eine Applikation</span>

      <span>Unsere Applikation nutzt das „Skalierbare Zentrale Messverfahren“ (SZM) der INFOnline GmbH (<a class="underline text-[#F0811A]" href="http://www.infonline.de/">www.infonline.de</a>) für die Ermittlung statistischer Kennwerte zur Nutzung unserer Angebote. Dabei werden anonyme Messwerte erhoben. Die SZM-Reichweitenmessung verwendet zur Wiedererkennung von Geräten eindeutige Kennungen des Endgerätes, die ausschließlich anonymisiert übermittelt werden. IP-Adressen werden nur in anonymisierter Form verarbeitet.</span>
      <span>Weitere Informationen zum SZM-Verfahren finden Sie auf der Webseite der INFOnline GmbH (<a class="underline text-[#F0811A]" href="http://www.infonline.de/">www.infonline.de</a>), die das SZM-Verfahren betreibt, der DatenschutzWebseite der AGOF (<a class="underline text-[#F0811A]" href="http://www.agof.de/datenschutz">www.agof.de/datenschutz</a>) und der DatenschutzWebseite der IVW (<a class="underline text-[#F0811A]" href="http://www.ivw.eu/">www.ivw.eu</a>).</span>

      <span class="font-semibold text-base">14. Verwendung von Scriptbibliotheken (Google Webfonts)</span>

      <span>Um unsere Inhalte unabhängig vom benutzten Browser korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Webseite Scriptbibliotheken und Schriftbibliotheken wie z. B. Google Webfonts (<a class="underline text-[#F0811A]" href="https://www.google.com/webfonts/">www.google.com/webfonts</a>). Diese werden im Cache Ihres Browsers zwischengespeichert und verkürzen die Ladezeit. Falls der Browser die Google Webfonts nicht unterstützt oder den Zugriff unterbindet, werden Inhalte in einer Standardschrift angezeigt.</span>
      <span>Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken – dass Betreiber entsprechender Bibliotheken Daten erheben. Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: <a class="underline text-[#F0811A]" href="https://www.google.com/policies/privacy/">www.google.com/policies/privacy</a></span>

      <span class="font-semibold text-base">15. Verwendung von Google Maps</span>

      <span>Diese Webseite bindet Inhalte von Google Maps ein, um geographische Informationen visuell darzustellen. Bei der Nutzung von Google Maps werden von Google auch Daten über die Nutzung der Kartenfunktionen durch Besucher erhoben, verarbeitet und genutzt. Nähere Informationen über die Datenverarbeitung durch Google können Sie den <a class="underline text-[#F0811A]" href="http://www.google.com/privacypolicy.html">Google-Datenschutzhinweisen</a> entnehmen. Dort können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern. Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten finden Sie <a class="underline text-[#F0811A]" href="http://www.dataliberation.org/">hier</a>.</span>

      <span class="font-semibold text-base">16. Eingebettete YouTube-Videos</span>

      <span>Auf einigen unserer Webseiten betten wir Youtube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie eine Seite mit einem eingebetteten Youtube-Video aufrufen, wird eine Verbindung zu Servern von Youtube hergestellt. Dabei wird Youtube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem Youtube-Konto eingeloggt sind, kann Youtube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem Youtube-Account ausloggen.</span>
      <span>Wird ein Youtube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten sammeln. Wer das Speichern von Cookies für das Google-Ad-Programm deaktiviert hat, wird auch beim Anschauen von Youtube-Videos mit keinen solchen Cookies rechnen müssen. Youtube legt aber auch in anderen Cookies nicht-personenbezogene Nutzungsinformationen ab. Möchten Sie dies verhindern, so müssen Sie das Speichern von Cookies im Browser blockieren.</span>
      <span>Weitere Informationen zum Datenschutz bei „Youtube“ finden Sie in der Datenschutzerklärung des Anbieters unter: <a class="underline text-[#F0811A]" href="https://www.google.de/intl/de/policies/privacy/">www.google.de/intl/de/policies/privacy</a></span>

      <span class="font-semibold text-base">17. Social Plugins</span>

      <span>Auf unseren Webseiten werden sogenannte „Social Plugins“ der unten aufgeführten Anbieter eingesetzt. Die Plugins stellen Schnittstellen zu sozialen Netwerken dar und sind mit den entsprechenden Logos gekennzeichnet.</span>
      <span>Über diese Plugins werden unter Umständen Informationen, zu denen auch personenbezogene Daten gehören können, an den Dienstebetreiber gesendet und ggf. von diesem genutzt. Wir verhindern die unbewusste und ungewollte Erfassung und Übertragung von Daten an den Diensteanbieter durch eine 2-Klick-Lösung. Um ein gewünschtes Social Plugin zu aktivieren, muss dieses erst durch Klick auf den entsprechenden Schalter aktiviert werden. Erst durch diese Aktivierung des Plugins wird auch die Erfassung von Informationen und deren Übertragung an den Diensteanbieter ausgelöst. Wir erfassen selbst keine personenbezogenen Daten mittels der Social Plugins oder über deren Nutzung.</span>
      <span>Wir haben keinen Einfluss darauf, welche Daten ein aktiviertes Plugin erfasst und wie diese durch den Anbieter verwendet werden. Derzeit muss davon ausgegangen werden, dass eine direkte Verbindung zu den Diensten des Anbieters ausgebaut wird sowie mindestens die IP-Adresse und gerätebezogene Informationen erfasst und genutzt werden. Ebenfalls besteht die Möglichkeit, dass die Diensteanbieter versuchen, Cookies auf dem verwendeten Rechner zu speichern. Welche konkreten Daten hierbei erfasst und wie diese genutzt werden, entnehmen Sie bitte den Datenschutzhinweisen des jeweiligen Diensteanbieters. Hinweis: Falls Sie zeitgleich bei Facebook angemeldet sind, kann Facebook Sie als Besucher einer bestimmten Seite identifizieren.</span>
      <span>Wir haben auf unserer Webseite die Social-Media-Buttons folgender Unternehmen eingebunden:<br>Facebook Inc. (1601 S. California Ave - Palo Alto - CA 94304 - USA)<br>Twitter Inc. (795 Folsom St. - Suite 600 - San Francisco - CA 94107 - USA)<br>Google Plus/Google Inc. (1600 Amphitheatre Parkway - Mountain View - CA 94043 - USA)</span>

      <span class="font-semibold text-base">18. SSL-Verschlüsselung</span>

      <span>Wir garantieren Ihnen die Sicherheit Ihrer Daten und verwenden dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</span>

      <span class="font-semibold text-base">19. Änderung unserer Datenschutzbestimmungen</span>

      <span>Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z. B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</span>

      <span class="font-semibold text-base">20. Fragen an den Datenschutzbeauftragten</span>

      <span>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an unseren Datenschutzbeauftragten Olav Seyfarth: <a class="underline text-[#F0811A]" href="mailto:datenschutz@reiff.de">datenschutz&#64;reiff.de</a></span>


    </div>
  </div>
</kaz-default-frame>


import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'kaz-ad-info',
    templateUrl: './kaz-ad-info.component.html',
    styleUrls: ['./kaz-ad-info.component.scss'],
    standalone: false
})
export class KazAdInfoComponent {
  showAdInfo: boolean = true;
  @Output() closeInfoOnEmit = new EventEmitter();

  closeAdInfo() {
    this.showAdInfo = false;
    this.closeInfoOnEmit.emit();
  }
}

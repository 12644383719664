import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./pages/auth/login/login.component";
import {ResetComponent} from "./pages/auth/reset/reset.component";
import {SignupComponent} from "./pages/auth/signup/signup.component";
import {MainComponent} from "./pages/main/main.component";
import {ProfileComponent} from "./pages/profile/profile.component";
import {InfoComponent} from "./pages/info/info.component";
import {CreateAdComponent} from "./pages/ad/create-ad/create-ad.component";
import {AuthGuard} from "./guards/auth.guard";
import {DetailAdComponent} from "./pages/ad/detail-ad/detail-ad.component";
import {EditAdComponent} from "./pages/ad/edit-ad/edit-ad.component";
import {ImprintComponent} from "./pages/footer/imprint/imprint.component";
import {PrivacyComponent} from "./pages/footer/privacy/privacy.component";
import {ConditionsComponent} from "./pages/footer/conditions/conditions.component";
import {FundamentalsComponent} from "./pages/footer/fundamentals/fundamentals.component";
import {AgbComponent} from "./pages/footer/agb/agb.component";
import {FaqComponent} from "./pages/faq/faq.component";
import {SearchAdComponent} from "./pages/ad/search-ad/search-ad.component";
import {VerifyComponent} from "./pages/auth/verify/verify.component";
import {SuccessAdComponent} from "./pages/ad/create-ad/success-ad/success-ad.component";
import {CancelAdComponent} from "./pages/ad/create-ad/cancel-ad/cancel-ad.component";
import {ValidateComponent} from "./pages/auth/validate/validate.component";
import {ResetPasswordComponent} from "./pages/auth/reset-password/reset-password.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'main',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'verify/:id/:token',
    component: VerifyComponent,
  },
  {
    path: 'validate/:id',
    component: ValidateComponent,
  },
  {
    path: 'reset-password/:id/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'reset',
    component: ResetComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'main',
    component: MainComponent,
  },
  {
    path: 'profile',
    children: [
      {path:'', redirectTo: 'menu/entries', pathMatch: 'full'},
      {path:'menu/:menu', component: ProfileComponent, canActivate:[AuthGuard]},
      {path:'info', component: InfoComponent},
    ],
  },
  {
    path: 'entry/create',
    component: CreateAdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'entry/search',
    component: SearchAdComponent,
  },
  {
    path: 'entry/:id/success',
    component: SuccessAdComponent,
  },
  {
    path: 'entry/:id/cancel',
    component: CancelAdComponent,
  },
  {
    path: 'entry/:id',
    component: DetailAdComponent,
  },
  {
    path: 'entry/edit/:id',
    component: EditAdComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'imprint',
    component: ImprintComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'conditions',
    component: ConditionsComponent,
  },
  {
    path: 'fundamentals',
    component: FundamentalsComponent,
  },
  {
    path: 'agb',
    component: AgbComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {AdList} from "../../interfaces/ad";
import {AuthenticationService} from "../../services/auth.service";
import {CursorToken} from "../../interfaces/utils";

@Component({
    selector: 'kaz-ads',
    templateUrl: './kaz-ads.component.html',
    styleUrls: ['./kaz-ads.component.scss'],
    standalone: false
})
export class KazAdsComponent implements OnInit {
  ownAds: AdList | null = null;

  nextToken: CursorToken = "@start";

  constructor(private _api: ApiService,
              private _auth: AuthenticationService) {
  }

  async ngOnInit() {
    await this.getActiveAds()
  }

  async getActiveAds() {
    try {
      this.ownAds = await this._api.getActiveAdList(this.nextToken, 24);
    } catch (err) {

    }
  }

  refreshActiveAds(){
    this.getActiveAds().then();
  }
}

<kaz-default-frame>
  <div *ngIf="ad !== null" class="flex flex-col md:grid md:grid-cols-[auto_1fr] md:gap-y-8 md:gap-x-24 md:mb-[118px]">
    <div class="relative flex w-full pb-4 items-center justify-center md:hidden">
      <span class="text-base font-medium text-[#222222]">Bearbeiten</span>
    </div>
    <!-- Titel-Label-->
    <span class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium mt-9">Titel</span>
    <!-- AdType & Title & TitleInfo -->
    <div class="flex flex-col">
      <!-- AdType -->
      <div class="flex flex-row gap-4 items-center">
        <!-- Radio Btn -->
        <div *ngIf="ad.adType === AdType.WANTED || ad.adType === AdType.OFFER" class="flex flex-row gap-1.5 text-sm text-[#161D26] font-medium" (change)="onAdTypeChange(AdType.OFFER)">
          <input type="radio" [id]="AdType.OFFER" [name]="AdType.OFFER" [value]="AdType.OFFER"
                 [checked]="ad.adType === AdType.OFFER">
          <label [for]="AdType.OFFER">Angebot</label>
        </div>
        <!-- Radio Btn -->
        <div *ngIf="ad.adType === AdType.WANTED || ad.adType === AdType.OFFER" class="flex flex-row gap-1.5 text-sm text-[#161D26] font-medium" (change)="onAdTypeChange(AdType.WANTED)">
          <input type="radio" [id]="AdType.WANTED" [name]="AdType.WANTED" [value]="AdType.WANTED"
                 [checked]="ad.adType === AdType.WANTED">
          <label [for]="AdType.WANTED">Gesuch</label>
        </div>
        <!-- Radio Btn -->
        <div *ngIf="ad.adType === AdType.GOOD_DEED" class="flex flex-row gap-1.5 text-sm text-[#161D26] font-medium" (change)="onAdTypeChange(AdType.GOOD_DEED)">
          <input type="radio" [id]="AdType.GOOD_DEED" [name]="AdType.GOOD_DEED" [value]="AdType.GOOD_DEED"
                 [checked]="ad.adType === AdType.GOOD_DEED">
          <label [for]="AdType.GOOD_DEED">Gute Tat</label>
        </div>
      </div>
      <!-- Title -->
      <kaz-input class="flex mt-4" [(value)]="ad.title" [maxLength]="40" placeholder="Title *"></kaz-input>
      <div class="grid grid-cols-[1fr_auto] text-xs text-[#999999] font-medium px-2 mt-1.5">
        <span>max. 40 Zeichen</span>
        <span>{{ad.title.length}}/40</span>
      </div>
    </div>
    <!-- Divider -->
    <div class="h-2 w-full bg-[#FAFAFA] my-5 md:hidden"></div>
    <!-- Beschreibungs-Label-->
    <span class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium">Beschreibung</span>
    <!-- Beschreibung & BeschreibungsInfo -->
    <div class="flex flex-col">
      <!-- Beschreibung -->
      <textarea [(ngModel)]="ad.description" maxlength="300"
                class="p-4 w-full h-[205px] leading-6 resize-none text-base text-[#F0811A] font-medium border border-[#999999] bg-[#FAFAFA] rounded-[6px]"
                placeholder="Beschreibung">
    </textarea>
      <div class="grid grid-cols-[1fr] text-xs text-[#999999] font-medium px-2 mt-1.5">
        <span class="text-end">{{ad.description.length}}/300</span>
      </div>
    </div>
    <!-- Preis-Label-->
    <span *ngIf="ad.adType !== AdType.GOOD_DEED" class="hidden md:flex md:text-[#161D26] md:text-lg md:font-medium md:mt-9">Preis</span>
    <!-- Preistyp & Preis & PreisInfo -->
    <div *ngIf="ad.adType !== AdType.GOOD_DEED" class="flex flex-col">
      <!-- Divider -->
      <div class="h-2 w-full bg-[#FAFAFA] my-5 md:hidden"></div>
      <!-- Radio Buttons - Preistyp -->
      <div class="flex flex-row gap-4 items-center mb-4">
        <!-- Radio Btn -->
        <div class="flex flex-row gap-1.5 text-sm text-[#161D26] font-medium"
             (click)="onAdPriceTypeChange(PriceType.FP)">
          <input type="radio" id="fixedPrice" name="price" [checked]="ad.priceType === PriceType.FP">
          <label for="fixedPrice">Festpreis</label>
        </div>
        <!-- Radio Btn -->
        <div class="flex flex-row gap-1.5 text-sm text-[#161D26] font-medium"
             (click)="onAdPriceTypeChange(PriceType.VHB)">
          <input type="radio" id="basisPrice" name="price" [checked]="ad.priceType === PriceType.VHB">
          <label for="basisPrice">Verhandlungsbasis</label>
        </div>
      </div>
      <!-- Price -->
      <div class="relative flex items-center w-full md:max-w-[343px]">
        <span class="absolute right-5 text-base font-medium">{{ad.priceType === PriceType.FP ? '€' : 'VHB €'}}</span>
        <input
          class="w-full h-12 pl-4 pr-20 leading-4 text-base font-medium border border-[#999999] bg-[#FAFAFA] rounded-[6px]"
          maxlength="10"
          title="Preis" type="text" [(ngModel)]="price" (input)="formatInput($event)" (focusin)="checkPrice($event)"
          (keydown)="allowOnlyNumbers($event)">
      </div>
    </div>
    <!-- Divider -->
    <div class="h-2 w-full bg-[#FAFAFA] my-5 md:hidden"></div>
  </div>
  <div class="flex flex-col gap-4 md:flex-row">
    <kaz-button [disabled]="isFormIncomplete()" width="100%" class="md:grow md:max-w-[343px]"
                (onClick)="updateAd()" text="Anzeige speichern">
    </kaz-button>
    <div class="flex flex-row h-12 w-full border border-[#F0811A] text-base text-[#F0811A] font-semibold items-center justify-center rounded-full md:max-w-[343px] md:cursor-pointer" (click)="goBack()">
      <span>Abbrechen</span>
    </div>
  </div>

</kaz-default-frame>

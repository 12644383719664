<div *ngIf="showDefaultInfo" class="relative flex flex-col w-[330px] h-[364px] p-4 text-sm text-[#161D26] font-semibold leading-[21px] bg-white border border-[#F1F1F1] rounded-[6px] shadow">
  <fa-icon class="absolute right-2.5 top-2.5 w-5 h-5 cursor-pointer" icon="xmark" (click)="closeDefaultInfo()"></fa-icon>
  <span class="text-xl text-[#F0811A] mb-2">Herzlich willkommen bei kleinanzeigen.bo.de!</span>
  <span class="font-normal mb-3">Bitte erstellen Sie ein Konto oder loggen Sie sich in Ihr bereits bestehendes Konto ein.</span>
  <span class="font-semibold mb-6">Nutzen Sie alle Vorteile:</span>
  <div class="grid grid-cols-[auto_1fr] gap-x-2 gap-y-4">
    <fa-icon class="text-[#F0811A]" [icon]="['fas','circle-check']"></fa-icon>
    <span>Kleinanzeigen erscheinen immer online und zusätzlich in der Gesamtausgabe der Mittelbadischen Presse</span>

    <fa-icon class="text-[#F0811A]" [icon]="['fas','circle-check']"></fa-icon>
    <span>Chatfunktion zur schnelleren Kontaktaufnahme</span>
  </div>
</div>



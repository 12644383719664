import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../../services/api.service";
import {Location} from "@angular/common";
import {Ad} from "../../../../interfaces/ad";

@Component({
    selector: 'kaz-success-ad',
    templateUrl: './success-ad.component.html',
    styleUrls: ['./success-ad.component.scss'],
    standalone: false
})
export class SuccessAdComponent implements OnInit{
  ad: Ad | null = null;

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _api: ApiService) {
  }
  ngOnInit() {
    this._route.params.subscribe(params => {
      const adId = params['id']!;
      this.getAd(adId).then();
      setTimeout(() => {
        this._router.navigate(['ad/' + adId]).then();
      }, 4000);
    });
  }

  async getAd(id: string) {
    try {
      if (id !== '') {
        this.ad = await this._api.getAd(id);
      }
    } catch (err) {

    }
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {ApiService} from "../../../services/api.service";

@Component({
    selector: 'kaz-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['./reset.component.scss'],
    standalone: false
})
export class ResetComponent implements OnInit, OnDestroy {
  mail: string = '';
  subscription: Subscription | null = null;
  wasSend: boolean = false;

  constructor(private _Router: ActivatedRoute,
              private _api: ApiService) {
  }

  ngOnInit() {
    this.subscription = this._Router.queryParams.subscribe(params => {
      this.mail = params['mail'] ?? '';
    })
  }

  ngOnDestroy(){
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  isDisabled() {
    return !/[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}/.test(this.mail);
  }

  async sendResetMail() {
    try {
      const data = await this._api.setResetPasswordToken(this.mail);
      this.wasSend = true;
    } catch (err) {
      console.log(err);
    }
  }
}

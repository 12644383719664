import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthenticationService} from "../../services/auth.service";
import {ApiService} from "../../services/api.service";
import {NavigationBarItem} from "../../interfaces/navigation";

@Component({
    selector: 'kaz-menu',
    templateUrl: './kaz-menu.component.html',
    styleUrls: ['./kaz-menu.component.scss'],
    standalone: false
})
export class KazMenuComponent implements OnInit{
  @Output() closeMenu = new EventEmitter<boolean>
  mail: string | undefined = undefined;

  constructor(public _auth: AuthenticationService,
              private _api: ApiService) {
 }

 ngOnInit() {
    this.mail = this._auth.getClaimsFromToken()?.mail;
 }

  logout() {
    this._auth.clearAuth();
  }

  onCloseMenuClick() {
    this.closeMenu.emit();
  }

  protected readonly NavigationBarItem = NavigationBarItem;
}

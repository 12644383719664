<script src="../../services/auth.service.ts"></script>
<div *ngIf="showFastMessage && ad !== null" class="fixed top-0 left-0 w-full h-full bg-black/70">
  <div class="fixed top-0 left-0 flex flex-col p-4 w-full h-full bg-white md:bg-[#FAFAFA] md:w-[1080px] md:h-auto md:max-h-[100vh] md:overflow-y-scroll md:px-16 md:pb-16 md:pt-8 md:rounded-[20px] md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2">
    <!-- Header -->
    <div class="relative flex flex-row h-12 justify-center items-center">
      <span (click)="closeFastMessage()" class="absolute left-4 text-xs text-[#224261] md:text-base md:cursor-pointer">Abbrechen</span>
      <span class="grow text-base text-center">Nachricht</span>
      <span class="absolute right-4 text-xs md:text-base md:font-semibold" [class]="message !== '' ? 'md:cursor-pointer text-[#F0811A]' : 'pointer-events-none text-[#A7A7A7]'" (click)="createMessage()">Senden</span>
    </div>
    <!-- Divider -->
    <div class="h-[1px] bg-[#EFEFEF]"></div>
    <!-- Provider -->
    <div class="flex flex-row gap-4 my-4 items-center">
      <!-- Bild -->
      <div class="relative flex overflow-hidden w-10 h-10 bg-[#F5F5F5] justify-center items-center rounded-full"
           routerLink="/entry/{{ad.id}}">
        <img class="w-auto h-full object-cover" [src]="ad.images.length > 0 ? (ad.images[ad.titleImageIndex] | imageSource) : getDummyPicture(ad)" alt="Bild">
      </div>
      <div class="flex flex-col font-semibold">
        <span class="text-sm text-[#222222]">{{ad.title}}</span>
        <div class="text-xs text-[#999999]">
          <fa-icon icon="hashtag"></fa-icon>
          <span>{{ad.cipher.number}}</span>
        </div>
        <span *ngIf="ad.category.subName !== 'Verschenken' && +ad.price > 0"
          class="text-xs text-[#56BA90]">€ {{ad.price | currency:'EUR':'':'1.0-0':'de-DE'}} {{ad.priceType === PriceType.VHB ? 'VHB' : '' }}</span>
      </div>
    </div>
    <!-- Nachricht -->
    <textarea [(ngModel)]="message"
      class="p-4 mt-2 h-[205px] leading-6 resize-none text-base text-[#161D26] font-medium border border-[#999999] rounded-[6px] bg-[#F7F7F7]"
      placeholder="Schreibe eine Nachricht, denke daran auch deine Kontaktdaten für Rückfragen anzugeben oder aktiviere die Checkbox"></textarea>
    <span *ngIf="ad.cipher.type !== CipherType.NONE && ad.cipher.type !== CipherType.CHAT" class="text-xs text-[#999999] font-semibold px-2 mt-1.5">Hierbei handelt es sich um eine Chiffre-Anzeige. Der Anzeigenerfasser entscheidet dann selbstständig wie und ob er sich bei Ihnen meldet.</span>
    <label class=" cursor-pointer flex flex-row gap-2 mt-8 text-sm font-medium text-kaz-black-text">
      <input type="checkbox" [(ngModel)]="sendContactData">
      <span>Ich möchte, dass meine Kontaktdaten (Vorname, Name, E-Mail-Adresse) an der Nachricht mit angefügt werden.</span>
    </label>
  </div>
</div>

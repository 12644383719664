<kaz-default-frame>
  <div class="flex flex-col gap-8 text-sm text-[#222222] pb-6">
    <span class="font-semibold text-xl">AGB</span>

    <div class="flex flex-col gap-4">
      <span>1. Die Preise der jeweils gültigen Privatpreisliste gelten für von Privatpersonen aufgegebene Gelegenheitsanzeigen. Als 'gelegentlich' gilt, wenn nicht mehr als 3 Objekte einer bestimmten Anzeigenrubrik pro Jahr angeboten bzw. nachgefragt werden.</span>
      <span>2. Wiederholungsanzeigen mit Werbung, z.B. für zum Verkauf hergestellte Waren oder gewerbliche Dienstleistungen aller Art sind keine privaten Gelegenheitsanzeigen. Geschäftliche Werbung im Gewand der privaten Kleinanzeigen verstößt gegen das UWG (Gesetz gegen unlauteren Wettbewerb) und kann rechtliche Konsequenzen nach sich ziehen.</span>
      <span>3. Anzeigen anonymer Auftraggeber werden aus presserechtlichen Gründen nicht veröffentlicht.</span>
      <span>4. Der Auftraggeber ist zu wahrheitsgemäßen Angaben im Anzeigentext verpflichtet. Die Fotos in der Anzeige müssen das beschriebene Objekt abbilden und dürfen nicht manipuliert sein.</span>
      <span>5. Der Verlag behält sich vor, Anzeigenaufträge wegen des Inhalts, der Herkunft oder der technischen Form nach einheitlichen, sachlich gerechtfertigten Grundsätzen des Verlages abzulehnen, wenn deren Inhalt gegen Gesetze oder behördliche Bestimmungen verstößt oder deren Veröffentlichung für den Verlag unzumutbar ist. Anzeigen, die gegen die guten Sitten oder gesetzliche Bestimmungen verstoßen, werden nicht veröffentlicht.</span>
      <span>6. Die Einstellung von Anzeigeninhalten durch den Auftraggeber im Namen Dritter ist ohne deren ausdrückliche vorherige Zustimmung unzulässig. Insbesondere dürfen keine Adressen, E-Mail-Adressen oder Telefonnummern von Dritten ohne deren Einwilligung veröffentlicht werden.</span>
      <span>7. Der Auftraggeber stellt den Verlag im Falle der Inanspruchnahme durch Dritte aufgrund von Ansprüchen in Zusammenhang mit veröffentlichten Anzeigen, die gegen gesetzliche Vorschriften verstoßen, frei. Insbesondere ist vom Auftraggeber sicherzustellen, dass Bilder, die in der Anzeige veröffentlicht werden sollen, frei sind von Rechten Dritter. Der Auftraggeber ist verpflichtet, allen Schaden zu ersetzen, der dem Verlag infolge der Veröffentlichung seiner Anzeige entsteht.</span>
      <span>8. Der Verlag behält sich vor, geringe Größen- oder Textänderungen vorzunehmen. Kleinanzeigen sind Fließtextanzeigen. Daher kann sich bei der Bestellung einer Kleinanzeige per Internet nicht auf ein Layout festgelegt werden. Die in der Zeitung abgedruckte Anzeige kann also in der Darstellung von der im Internet dargestellten Vorschau abweichen. Darüber hinaus übernimmt der Verlag weder die Haftung für Schreibfehler, noch kann ein Erreichen der nächsten Ausgabe, auch unter Einhaltung der Anzeigenschlusstermine, sicher garantiert werden. Die Rubrizierung des Kunden wird vom Verlag als Vorschlag zur Einordnung betrachtet. Der Verlag behält sich vor, Anzeigen in andere gleichwertige Rubriken einzuordnen, wenn ihm das notwendig erscheint.</span>
      <span>9. Sollten sich einzelne Bestimmungen dieser AGB als unwirksam oder undurchführbar erweisen oder infolge Änderungen der Gesetzgebung nach Vertragsabschluss unwirksam oder undurchführbar werden, bleiben die übrigen Vertragsbestimmungen und die Wirksamkeit im Ganzen hiervon unberührt. An Stelle der unwirksamen oder undurchführbaren Bestimmung tritt die wirksame und durchführbare Bestimmung, die dem Sinn und Zweck der nichtigen Bestimmung möglichst nahe kommt.</span>

      <span class="font-semibold text-base">Informationspflicht nach § 37 VSBG:</span>
      <span>Der Verlag/die Firma ist grundsätzlich nicht bereit und verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</span>

      <span class="font-semibold text-xl">Widerrufsbelehrung und Rechtsfolgen</span>

      <div class="flex flex-col gap-4">
        <span class="font-semibold text-base">Widerrufsrecht</span>
        <span> Der Auftraggeber hat das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses.</span>

        <span class="font-semibold text-base">Um Ihr Widerrufsrecht auszuüben, müssen Sie uns</span>

        <span class="font-semibold text-base">An:<br>Reiff Verlag GmbH & Co. KG<br>Marlener Str.9<br>77656 Offenburg<br>Telefax: 0781/504-1439<br>E-Mail-Adresse: <a
          class="underline text-[#F0811A]" href="mailto:kleinanzeigen@reiff.de">kleinanzeigen&#64;reiff.de</a></span>

        <span class="font-semibold text-base">mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.  Sie können aber auch das Widerrufsformular auf unserer Webseite (Widerrufsformular) elektronisch ausfüllen und übermitteln. Machen Sie von dieser Möglichkeit Gebrauch, so werden wir Ihnen unverzüglich (per E-Mail) eine Bestätigung über den Eingang eines solchen Widerrufs übermitteln.</span>

        <span class="font-semibold text-base">Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</span>

        <br>

        <span class="font-semibold text-base">Folgen des Widerrufs</span>
        <span>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</span>
        <span>Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.</span>

        <span class="font-semibold text-base">Besondere Hinweise:</span>
        <span>Ihr Widerrufsrecht erlischt vorzeitig, wenn Sie vor Ausführung des Vertrags ausdrücklich zugestimmt haben, dass mit der Ausführung des Vertrags vor Ablauf der Widerrufsfrist begonnen wird und wir unsere Leistung vollständig erbracht haben.</span>
        <span>Das Widerrufsrecht besteht nur für Verbraucher i.S.d. § 13 BGB, also für jede natürliche Person, die ein Rechtsgeschäft abschließt, das überwiegend weder ihrer gewerblichen noch ihrer selbstständigen beruflichen Tätigkeit zugerechnet werden kann.</span>
      </div>
    </div>
  </div>
</kaz-default-frame>

import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
    selector: 'kaz-default-frame',
    templateUrl: './default-frame.component.html',
    styleUrls: ['./default-frame.component.scss'],
    standalone: false
})
export class DefaultFrameComponent implements OnInit {

  @Input() scrollable: boolean = true;

  backLink: string = "";
  showMenu: boolean = false;

  showAuthBtns: boolean = false;
  mail: string = "";

  constructor(public _auth: AuthenticationService,
              private _router: Router) {
  }

  ngOnInit() {
    this.isAuthenticated();
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  isProfilePage(): boolean {
    return this._router.url.includes('/profile');
  }

  isAuthenticated() {
    this.showAuthBtns = !this._auth.isAuthenticated();
    const claims = this._auth.getClaimsFromToken();
    if (claims !== null) {
      if (claims.userType === 'WEB_USER') {
        this.mail = claims.mail;
      }
      if (claims.userType === 'ADMIN_USER') {
        this._auth.clearAuth();
        this._router.navigate(['login']).then();
      }
    }
  }

  closeMenu() {
    this.showMenu = false;
  }

  activateAccountMenu() {
    return this._router.url.includes('login') || this._router.url.includes('signup') || this._router.url.includes('profile');
  }

  activateCreateAdMenu() {
    return this._router.url.includes('entry/create');
  }

  logout() {
    this._auth.clearAuth();
  }

  showFilter() {
    this._router.navigate(['/entry/search']).then();
  }
}


import { Component } from '@angular/core';
import {slideAnimation} from "../../animations/slide.animations";

interface FaqItem {
  active: boolean,
  header: string,
  data: string,
}

@Component({
    selector: 'kaz-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    standalone: false
})

export class FaqComponent {
items: FaqItem[] = [
  {
    active: false,
    header: '1. Welche Möglichkeiten habe ich, um eine private Kleinanzeige aufzugeben?',
    data: 'Sie können eine Kleinanzeige online im Portal oder telefonisch unter 0781/504-5533 aufgeben.',
  },
  {
    active: false,
    header: '2. Welche Schritte sind nun notwendig, um eine Kleinanzeige aufzugeben?',
    data: '• Wenn Sie bereits ein Benutzerkonto angelegt haben, loggen Sie sich mit Ihren Zugangsdaten ein.\n' +
      '\n' +
      '•  Andernfalls erstellen Sie bitte unter dem Button „Registrieren“ Ihr Benutzerkonto.\n' +
      '\n' +
      '•  Wählen Sie anschließend oben links auf der Website den Button „Anzeige aufgeben“.\n' +
      '\n' +
      '•  Wählen Sie eine Kategorie aus.\n' +
      '\n' +
      '•  Legen Sie einen Titel fest.\n' +
      '\n' +
      '•  Beschreiben Sie ihren Artikel.\n' +
      '\n' +
      '•  Fügen Sie ggf. ein entsprechendes Bild hinzu.\n' +
      '\n' +
      '•  Legen Sie den Verkaufspreis fest.\n' +
      '\n' +
      '•  Wählen Sie die gewünschten Erscheinungstermine aus.\n' +
      '\n' +
      'Hinweis: Ein ausgewählter Erscheinungstermin stellt sich orangefarben dar. Um den Termin wieder zu deaktivieren, klicken Sie erneut auf den Termin.\n' +
      '\n' +
      '•  Klicken Sie auf den Button „Anzeige veröffentlichen.\n',
  },
  {
    active: false,
    header: '3. Wo erscheint meine Anzeige?',
    data: 'Der Kleinanzeigenmarkt erscheint immer mittwochs und samstags in allen fünf Lokalausgaben der Mittelbadischen Presse. Zusätzlich steht Ihre Anzeige ab dem ersten Erscheinungsdatum für vier Wochen auf dem Portal http://kleinanzeigen.bo.de',
  },
  {
    active: false,
    header: '4. Was ist eine Chiffre Anzeige und wie kann ich darauf antworten?',
    data: 'Der Inserent möchte bei dieser Anzeigenart zunächst anonym bleiben. Um Kontakt aufzunehmen, übermitteln Sie Ihre Nachricht:  \n' +
      '\n' +
      '•  Online über das Chat-Formular\n' +
      '\n' +
      '•  per Post an: Reiff Verlag GmbH &Co. KG, Chiffreabteilung, Marlener Str. 9, 77656 Offenburg\n' +
      '\n' +
      'Bitte beachten: Chiffre-Nr. außen auf dem Umschlag vermerken!\n' +
      '\n' +
      'Ihre Nachricht wird von uns an den Inserenten weitergeleitet.',
  },
  {
    active: false,
    header: '5. Wann ist Anzeigenschluss?',
    data: 'Kleinanzeigen erscheinen immer mittwochs und samstags.\n' +
      '\n' +
      'Anzeigenschluss für Mittwoch: Dienstag, 10:00 Uhr\n' +
      '\n' +
      'Anzeigenschluss für Samstag: Donnerstag, 15:00 Uhr',
  },
  {
    active: false,
    header: '6. Warum wurde meine Anzeige gesperrt?',
    data: 'Die Mittelbadische Presse behält sich vor, Anzeigen deren Inhalt den Nutzungsbedingungen widerspricht nicht zu veröffentlichen. Des Weiteren werden ausschließlich Anzeigen aus dem Verbreitungsgebiet der Mittelbadischen Presse veröffentlicht.'
  },
  {
  active: false,
  header: '7. Welche Anzeigen sind kostenlos?',
  data: 'Anzeigen der Rubrik „Gute Tat“ sind kostenlos, wenn der angebotene Artikel zu verschenken ist.',
}
];


}

import { Component } from '@angular/core';

@Component({
    selector: 'kaz-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss'],
    standalone: false
})
export class PrivacyComponent {

}

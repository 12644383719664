import {Component, Input, Output} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'kaz-search',
    templateUrl: './kaz-search.component.html',
    styleUrls: ['./kaz-search.component.scss'],
    standalone: false
})
export class KazSearchComponent {
  @Input() value: string = "";
  @Input() placeholder: string = "Wonach suchen Sie? | z.B Chiffre-Nr.";

  constructor(private _router: Router) {
  }

  search(search: string) {
    this._router.navigate(['/entry/search'], {queryParams: {search: search}}).then();   }

  showFilter() {
    this._router.navigate(['/entry/search']).then();

  }
}

import {Component, Input, OnInit} from '@angular/core';
import {Ad, PriceType} from "../../interfaces/ad";
import {getDummyPicture} from "../../utils/dummyImages";

@Component({
  selector: 'kaz-card-recommended',
  templateUrl: './kaz-card-recommended.component.html',
  styleUrls: ['./kaz-card-recommended.component.scss']
})
export class KazCardRecommendedComponent implements OnInit {
  @Input() ad: Ad | null = null;

  constructor() {
  }

  async ngOnInit() {
  }

  protected readonly getDummyPicture = getDummyPicture;
    protected readonly PriceType = PriceType;
}

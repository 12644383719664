export interface NavigationBar {
  name: "Anzeigen" | "Nachrichten" | "Archiv",
  value: NavigationBarItem,
  current: boolean
}

export enum NavigationBarItem {
  MESSAGES = 'messages',
  ENTRIES = 'entries',
  ARCHIVE = 'archive',
  FAVORITES = 'favorites',
}


import {Component, OnInit} from '@angular/core';
import {CookieService} from "ngx-cookie-service";

@Component({
    selector: 'kaz-cookie-banner',
    templateUrl: './kaz-cookie-banner.component.html',
    styleUrls: ['./kaz-cookie-banner.component.scss'],
    standalone: false
})
export class KazCookieBannerComponent implements OnInit{
  showCookies: boolean = false;

  constructor(private _cookies: CookieService) {
  }

  ngOnInit(): void {

  }

  async acceptCookies(){
    this.showCookies = false;
  }

  async denyCookies(){
    this.showCookies = false;
  }

  async editCokkies(){
    this.showCookies = false;
  }
}

<div *ngIf="ad !== null && !ad.reserved" class="relative flex flex-col border border-[#E4E4E4] bg-[#FEFEFE] rounded-[6px] h-[235px] w-[253px] overflow-hidden">
  <!-- Bild -->
  <a class="relative flex w-full h-[134px] bg-[#F5F5F5] justify-center items-center overflow-hidden md:cursor-pointer" routerLink="/entry/{{ad.id}}">
    <!-- BackgroundImage -->
    <img class="absolute left-0 top-0 z-0 w-full h-full object-cover blur"
         [src]="ad.images.length > 0 ? (ad.images[ad.titleImageIndex] | imageSource) : getDummyPicture(ad)" alt="Bild">
    <div class="absolute left-0 top-0 z-0 w-full h-full bg-black/50"></div>
    <img class="w-auto h-full z-0 object-cover" [src]="ad.images.length > 0 ? (ad.images[ad.titleImageIndex] | imageSource) : getDummyPicture(ad)" alt="Bild">
  </a>
  <!-- Text & Preis -->
  <a class="flex flex-col p-2.5 gap-3 md:cursor-pointer" routerLink="/entry/{{ad.id}}">
    <span class="text-base text-[#161D26] font-medium line-clamp-2 break-words hyphens-auto" lang="de" [title]="ad.title">{{ad.title}}</span>
    <span *ngIf="ad.category.subName !== 'Verschenken'  && +ad.price > 0" class="text-sm text-[#56BA90] font-medium">€ {{ad.price | currency:'EUR':'':'1.0-0':'de-DE'}} {{ad.priceType === PriceType.VHB ? 'VHB' : '' }}</span>
    <span class="absolute right-4 bottom-2.5 text-xs text-[#999999]/70 font-medium">{{ad.category.localityName !== '' ? ad.category.localityName : ad.category.subName}}</span>
  </a>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import {DateUtils} from "../utils/date";
import {RFC3339} from "../interfaces/datetime";

@Pipe({
    name: 'displaydate',
    standalone: false
})
export class DisplayDatePipe implements PipeTransform {
  constructor(){}

  transform(value: RFC3339, ...args: string[]) {
    if (args.includes('no-seconds')) {
      return DateUtils.fparseRFC3339(value, 'yyyy-MM-dd HH:mm');
    } else {
      return DateUtils.fparseRFC3339(value, 'yyyy-MM-dd HH:mm:ss');
    }
  }
}
